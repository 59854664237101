import * as uuid from 'uuid';
import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
	Autocomplete,
	Button,
	Chip,
	createFilterOptions,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	CurrencyField,
	HTMLInputElementExtended,
	HTMLTextAreaElementExtended,
	isFeatureEnabled,
	LoadingPage,
	STYLOVYZE_FLAGS,
	Text,
	UnitField,
	useIsFeatureEnabled,
	useSettings,
} from '@innovyze/stylovyze';
import { InfoOutlined } from '@mui/icons-material';
import {
	ConfigRecordTypeFieldData,
	DefectCode,
	RuleOptions,
	Rules,
} from '@Types';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import {
	Content,
	FullHeight,
	StyledForm,
	Wrapper,
} from './RuleConfiguration.styles';
import {
	ChoiceListPairs,
	getAllOperatorsForSpecificField,
	getChoiceListFromSpecificFile,
	getFieldOptions,
	getUnitStatus,
	getValueTypeForSpecificOperator,
	getFieldsData,
	getIsDisabledForSpecificField,
} from '@Utils/choiceListFilesUtils';
import {
	FIELDS_TYPES,
	RECORD_TYPE,
	recordTypeList,
} from '@Utils/choiceListMapping';
import useFormikInputValue from '@Components/hooks/useFormikInputValue';
import useYupSchemaCreate from '@Components/hooks/useYupSchemaCreate';
import { getFieldSystemUnitType, unitMapping } from '@Utils/units/units.utils';
import { populateFieldOptions } from '@Actions/ChoiceList.action';
import {
	selectAllRehabRuleChoiceListFiles,
	selectChoiceListIsLoading,
} from '@Selectors/ChoiceList.selectors';
import { isArray } from 'lodash-es';
import { useGlobalization } from '@Translations';
import { getObservationCodes } from '@innovyze/lib-am-common/Utils';

interface RuleConfigProps {
	groupedRuleId: string;
	ruleCondition: string;
	onAddRule: (rule: Rules) => void;
	onCancelRule: () => void;
	initialRuleValues: Rules;
	setQueryPanelDisabledState: (state: boolean) => void;
	openFilterHandler: () => void;
	isFilter: boolean;
	setTempRule: (rule: Rules) => void;
	allFilters: Rules[];
	setAllFilters: (allFilters: Rules[]) => void;
	systemType: string;
	assetType: string;
	inspectionStandard: string;
}

export const RuleConfiguration = ({
	ruleCondition,
	onAddRule,
	onCancelRule,
	initialRuleValues,
	setQueryPanelDisabledState,
	openFilterHandler,
	isFilter,
	setTempRule,
	allFilters,
	setAllFilters,
	systemType,
	assetType,
	inspectionStandard,
}: RuleConfigProps) => {
	const { t } = useGlobalization();
	const shouldShowCustomTableUnits = useIsFeatureEnabled(
		'info-360-asset-rehab-custom-table-units',
	);

	const dispatch = useDispatch();
	const [unitRequired, setUnitRequired] = useState(false);
	const formRef = useRef<FormikProps<any>>(null);
	// custom hooks

	const { companySettings } = useSettings();

	const [inputField, setValueType, valueType] = useFormikInputValue('', {
		dateFormat: companySettings.dateFormat,
	});
	const [validationSchema, setYupValueType] = useYupSchemaCreate(t);
	// add formik state
	const [fields, setFields] = useState<ChoiceListPairs[]>([]);
	const [operators, setOperators] = useState<ChoiceListPairs[]>([]);

	const [fieldUnit, setFieldUnit] = useState('');
	const choiceListIsLoading = selectChoiceListIsLoading();
	const [useDefault, setUseDefault] = useState(true);

	const [allowFilter, setAllowFilter] = useState(false);

	const recordTypeChoiceListFiles = selectAllRehabRuleChoiceListFiles();
	const [recordTypeWithConfig, setRecordTypeWithConfig] = useState(false);
	const [configFieldData, setConfigFieldData] =
		useState<ConfigRecordTypeFieldData>({ label: '', data: [] });
	const showMultiRiskModels = isFeatureEnabled(
		STYLOVYZE_FLAGS.REHAB_MULTIPLE_RISK_MODELS,
	);

	const showCustomTables = isFeatureEnabled(
		STYLOVYZE_FLAGS.REHAB_CUSTOM_TABLES_RECORD_TYPE,
	);
	const shouldUseQueryEnhance = isFeatureEnabled(
		'info360-rehab-query-enhancement',
	);
	const allRecordTypeWithConfig: string[] = showMultiRiskModels
		? [RECORD_TYPE.RISK, RECORD_TYPE.USER_DEFINED_FIELDS, RECORD_TYPE.TASK]
		: [];

	const [configValue, setConfigValue] = useState('');
	const [currentRecordValue, setCurrentRecordValue] = useState('');

	const recordTypeOptions = () => {
		const result = [
			{
				label: t('Asset attributes'),
				value: RECORD_TYPE.ASSET_ATTRIBUTES,
			},
			{
				label: t('Condition'),
				value: RECORD_TYPE.CONDITION,
			},
			{
				label: t('Inspection'),
				value: RECORD_TYPE.INSPECTION,
			},
		];

		result.push(
			{
				label: t('Risk'),
				value: RECORD_TYPE.RISK,
			},
			{
				label: t('Custom Table'),
				value: RECORD_TYPE.USER_DEFINED_FIELDS,
			},
			{
				label: t('Task'),
				value: RECORD_TYPE.TASK,
			},
		);

		return result.filter(
			record =>
				systemType === 'Sanitary Sewer' ||
				record.value === RECORD_TYPE.ASSET_ATTRIBUTES ||
				record.value === RECORD_TYPE.RISK ||
				record.value === RECORD_TYPE.USER_DEFINED_FIELDS ||
				record.value === RECORD_TYPE.TASK,
		);
	};

	const recordTypeOptionsFlagged = () => {
		if (!showCustomTables) {
			return recordTypeOptions().filter(obj => {
				return obj.value !== RECORD_TYPE.USER_DEFINED_FIELDS;
			});
		} else if (!shouldUseQueryEnhance) {
			return recordTypeOptions().filter(obj => {
				return obj.value !== RECORD_TYPE.TASK;
			});
		} else {
			return recordTypeOptions();
		}
	};
	const useStyles = makeStyles(() =>
		createStyles({
			paper: {
				width: 550,
			},
			newRuleNote: {
				backgroundColor: '#F1F4F4',
				margin: '.625rem',
				display: 'flex',
				alignItems: 'center',
				paddingBottom: '1rem',
			},
			newRuleNoteColor: {
				color: '#FD7B32',
			},
			newRuleNoteText: {
				//fontWeight:'300',
			},
			operatorSection: {
				marginTop: '2rem',
			},
			newRuleNoteIcon: {
				marginRight: '0.2rem',
				marginBottom: 'auto',
				marginTop: '0.1rem',
				fontSize: '.8rem',
			},
			LeftFilterNumButton: {
				marginBottom: '0.5rem',
				justifyContent: 'flex-start',
				backgroundColor: '#83BC40',
				borderRadius: '50px',
				cursor: 'text',
				'&:hover': {
					backgroundColor: '#83BC40',
				},
			},
			ActionDiv: {
				paddingRight: '1.5rem',
			},
			LeftButton: {
				justifyContent: 'flex-start',
			},
			RightButton: {
				justifyContent: 'flex-end',
			},
			defectRoot: {
				'& .MuiChip-root': {
					background: '#F1F4F4',
				},
				'& .MuiChip-label': {
					fontSize: '12px',
				},
			},
			codeListTag: {
				background: '#F1F4F4',
			},
			codeListOption: {
				'&[aria-selected="true"]': {
					background: '#007CA0',
					color: 'white',
				},
			},
		}),
	);

	const classes = useStyles();

	useEffect(() => {
		if (isFilter) {
			const recordTypeFields: ChoiceListPairs[] =
				getChoiceListFromSpecificFile(
					recordTypeChoiceListFiles,
					RECORD_TYPE.DEFECT_FILTER,
					'',
				);
			setFields(recordTypeFields);
		}
	}, [isFilter]);

	const getMeasurementUnit = useCallback(
		(newField: string, recordType: string) => {
			if (recordType === RECORD_TYPE.USER_DEFINED_FIELDS) {
				if (!shouldShowCustomTableUnits) {
					return '';
				}

				const customTableName = configValue || initialRuleValues.config;

				const localUnit = recordTypeChoiceListFiles.udtRecordType
					.find(record => record.tableName === customTableName)
					?.fields.find(
						(field: { key: string }) => field.key === newField,
					)?.unit;

				return localUnit || initialRuleValues.unit || '';
			} else if (recordType === RECORD_TYPE.TASK) {
				if (!shouldShowCustomTableUnits) {
					return '';
				}

				const taskTypeId = configValue || initialRuleValues.config;

				const localUnit = recordTypeChoiceListFiles.tasksRecordType
					.find(record => record.uploadId === taskTypeId)
					?.fields.find(
						(field: { key: string }) => field.key === newField,
					)?.unit;

				return localUnit || initialRuleValues.unit || '';
			}

			const fieldSystemUnitType = getFieldSystemUnitType(
				systemType,
				assetType,
				recordType,
				newField ?? '',
				inspectionStandard,
			);

			const fieldUnit = unitMapping(fieldSystemUnitType);

			return fieldUnit;
		},
		[
			initialRuleValues,
			systemType,
			assetType,
			inspectionStandard,
			configValue,
		],
	);

	useEffect(() => {
		if (initialRuleValues) {
			if (initialRuleValues.config) {
				setConfigValue(initialRuleValues.config);
			}
			if (
				initialRuleValues.recordType &&
				recordTypeList.includes(initialRuleValues.recordType)
			) {
				const recordTypeFields: ChoiceListPairs[] =
					getChoiceListFromSpecificFile(
						recordTypeChoiceListFiles,
						initialRuleValues.recordType,
						initialRuleValues.config,
					);
				setFields(recordTypeFields);
				setCurrentRecordValue(initialRuleValues.recordType);
			}
			if (
				initialRuleValues.recordType &&
				allRecordTypeWithConfig.includes(initialRuleValues.recordType)
			) {
				setRecordTypeWithConfig(true);
				setConfigFieldData(getConfigData(initialRuleValues.recordType));
			}
			if (
				initialRuleValues.field &&
				initialRuleValues.recordType != null
			) {
				if (
					(initialRuleValues.recordType == RECORD_TYPE.INSPECTION &&
						initialRuleValues.field.toLowerCase() ==
							'Defect_Count'.toLowerCase()) ||
					initialRuleValues.field.toLowerCase() ==
						'Defect_Count_Unit_Length'.toLowerCase()
				) {
					setAllowFilter(true);
				}

				const fieldOperators: ChoiceListPairs[] =
					getAllOperatorsForSpecificField(
						recordTypeChoiceListFiles,
						initialRuleValues.recordType,
						initialRuleValues.field,
						initialRuleValues.config,
					);
				setOperators(fieldOperators);

				const fieldOptions = getFieldOptions(
					recordTypeChoiceListFiles,
					initialRuleValues.recordType,
					initialRuleValues.field,
					initialRuleValues.config,
					// inspectionStandard,
				);

				dispatch(
					populateFieldOptions({
						fieldOptions: fieldOptions ? fieldOptions : [],
					}),
				);
			}

			if (
				initialRuleValues.operator &&
				initialRuleValues.recordType != null &&
				initialRuleValues.field != null
			) {
				const valueInputType = getValueTypeForSpecificOperator(
					recordTypeChoiceListFiles,
					initialRuleValues.recordType,
					initialRuleValues.field,
					initialRuleValues.operator,
					initialRuleValues.config,
				);
				setYupValueType(valueInputType);
				setValueType(valueInputType);
				const unitStatus = getUnitStatus(
					recordTypeChoiceListFiles,
					initialRuleValues.recordType,
					initialRuleValues.field,
					initialRuleValues.config,
				);
				setUnitRequired(unitStatus);

				const fieldUnit = getMeasurementUnit(
					initialRuleValues.field,
					initialRuleValues.recordType,
				);
				console.log('fieldUnit', fieldUnit);
				setFieldUnit(fieldUnit);
			}

			if (
				(!allFilters || allFilters.length == 0) &&
				initialRuleValues &&
				initialRuleValues.filters &&
				initialRuleValues.filters.length > 0
			) {
				setAllFilters(initialRuleValues.filters);
			}
		}
	}, [initialRuleValues]);

	const codes: DefectCode[] = useMemo(() => {
		const codes = getObservationCodes(inspectionStandard, t);
		return Object.keys(codes).map((key: string) => {
			return { code: key, desc: `${key} - ${codes[key]}` };
		});
	}, [inspectionStandard]);

	const codeStrings: string[] = useMemo(() => {
		const codes = getObservationCodes(inspectionStandard, t);
		return Object.keys(codes).map((key: string) => {
			return key;
		});
	}, [inspectionStandard]);

	const getConfigData = (recordType: string): ConfigRecordTypeFieldData => {
		if (recordType === RECORD_TYPE.RISK) {
			/*const data: ChoiceListPairs[] = riskConfigs.map(
				(config: { configId: string; name: string }) => {
					return {
						label: config.name,
						value: config.configId,
					};
				},
			);*/
			const data: ChoiceListPairs[] =
				recordTypeChoiceListFiles.riskRecordType.map(
					(ele: Record<string, unknown>) => {
						return {
							label: ele['name'] as string,
							value: ele['configId'] as string,
						};
					},
				);
			return {
				label: 'Risk Model',
				data: data,
			};
		} else if (recordType === RECORD_TYPE.USER_DEFINED_FIELDS) {
			const data: ChoiceListPairs[] =
				recordTypeChoiceListFiles.udtRecordType?.map((ele: any) => {
					return {
						label: ele['tableName'],
						value: ele['tableName'],
					};
				});
			return {
				label: 'Table Name',
				data: data,
			};
		} else if (recordType === RECORD_TYPE.TASK) {
			const data: ChoiceListPairs[] =
				recordTypeChoiceListFiles.tasksRecordType.map(
					(ele: Record<string, unknown>) => {
						return {
							label: ele['name'] as string,
							value: ele['uploadId'] as string,
						};
					},
				);
			return {
				label: 'Task',
				data: data,
			};
		}
		return {
			label: '',
			data: [],
		};
	};

	const addRuleToQuery = (newRule: Rules) => {
		onAddRule(newRule);
	};

	const resetValues = (
		setFieldValue: (
			field: string,
			value: unknown,
			shouldValidate?: boolean,
		) => void,
		setErrors: any,
		setFieldTouched: any,
	) => {
		setFieldValue('value1', '');
		setFieldValue('value2', '');
		setFieldTouched('value1', false, false);
		setFieldTouched('value2', false, false);
		setErrors({});
		setUseDefault(false);
	};

	// after formik functions
	const handleOutputTypeChange = (
		event: SelectChangeEvent<unknown>,
		setFieldValue: (
			field: string,
			value: unknown,
			shouldValidate?: boolean,
		) => void,
	) => {
		if (formRef && formRef.current) formRef.current.resetForm();
		const newOutputType = event.target.value as string;
		setFieldValue('recordType', newOutputType, false);
		setFieldUnit('');
		setFieldValue('field', '', false);
		setFieldValue('operator', '', false);
		setFieldValue('value1', '', false);
		setFieldValue('value2', '', false);
		setFieldValue('unitLength', '', false);
		setFieldValue('config', '', false);
		setCurrentRecordValue(newOutputType);
		if (newOutputType && recordTypeList.includes(newOutputType)) {
			if (
				newOutputType != RECORD_TYPE.USER_DEFINED_FIELDS &&
				newOutputType != RECORD_TYPE.RISK &&
				newOutputType != RECORD_TYPE.TASK
			) {
				const recordTypeFields: ChoiceListPairs[] =
					getChoiceListFromSpecificFile(
						recordTypeChoiceListFiles,
						newOutputType,
						'',
					);
				setFields(recordTypeFields);
			}

			if (allRecordTypeWithConfig.includes(newOutputType)) {
				setRecordTypeWithConfig(true);
				setConfigFieldData(getConfigData(newOutputType));
			} else {
				setRecordTypeWithConfig(false);
			}
		}
	};

	const handleConfigChange = (
		event: SelectChangeEvent<unknown>,
		setFieldValue: (
			field: string,
			value: unknown,
			shouldValidate?: boolean,
		) => void,
	) => {
		const newConfigValue = event.target.value as string;
		setFieldValue('config', newConfigValue, false);
		setFieldUnit('');
		setFieldValue('field', '', false);
		setFieldValue('operator', '', false);
		setFieldValue('value1', '', false);
		setFieldValue('value2', '', false);
		setFieldValue('unitLength', '', false);
		setConfigValue(newConfigValue);

		const recordTypeFields: ChoiceListPairs[] =
			getChoiceListFromSpecificFile(
				recordTypeChoiceListFiles,
				currentRecordValue,
				newConfigValue,
			);

		setFields(recordTypeFields);
	};

	const handleFieldChange = (
		event: SelectChangeEvent<unknown>,
		setFieldValue: (
			field: string,
			value: unknown,
			shouldValidate?: boolean,
		) => void,
		values: FormikValues,
	) => {
		if (isFilter) {
			setFieldValue('recordType', RECORD_TYPE.DEFECT_FILTER, false);
		}
		setFieldUnit('');
		setFieldValue('field', '', false);
		setFieldValue('operator', '', false);
		const newField = event.target.value as string;
		setFieldValue('field', newField, false);
		if (newField) {
			let recordType = values.recordType;
			if (isFilter) {
				recordType = RECORD_TYPE.DEFECT_FILTER;
				setAllowFilter(false);
			} else {
				if (
					recordType == RECORD_TYPE.INSPECTION &&
					(newField.toLowerCase() == 'Defect_Count'.toLowerCase() ||
						newField.toLowerCase() ==
							'Defect_Count_Unit_Length'.toLowerCase())
				) {
					setAllowFilter(true);
				} else {
					setAllowFilter(false);
				}
			}

			const fieldUnit = getMeasurementUnit(newField, recordType);
			setFieldUnit(fieldUnit);

			const fieldOperators: ChoiceListPairs[] =
				getAllOperatorsForSpecificField(
					recordTypeChoiceListFiles,
					recordType,
					newField,
					configValue,
				);
			setOperators(fieldOperators);

			const fieldOptions = getFieldOptions(
				recordTypeChoiceListFiles,
				recordType,
				newField,
				configValue,
				// inspectionStandard,
			);

			dispatch(
				populateFieldOptions({
					fieldOptions: fieldOptions ? fieldOptions : [],
				}),
			);
		}
	};
	const isDisabled = (key: string) => {
		if (currentRecordValue === RECORD_TYPE.ASSET_ATTRIBUTES) {
			const isFieldDisabled: boolean = getIsDisabledForSpecificField(
				recordTypeChoiceListFiles,
				currentRecordValue,
				key,
				'',
			);
			return isFieldDisabled;
		}
		return false;
	};
	const handleOperatorChange = (
		event: SelectChangeEvent<unknown>,
		setFieldValue: (
			field: string,
			value: unknown,
			shouldValidate?: boolean,
		) => void,
		values: FormikValues,
		setErrors: any,
		setFieldTouched: any,
	) => {
		const newOperators = event.target.value as string;
		if (newOperators) {
			let recordType = values.recordType;
			if (isFilter) {
				recordType = RECORD_TYPE.DEFECT_FILTER;
			}

			const valueInputType = getValueTypeForSpecificOperator(
				recordTypeChoiceListFiles,
				recordType,
				values.field,
				newOperators,
				configValue,
			);

			setYupValueType(valueInputType);
			setValueType(valueInputType);
			setFieldValue('operator', newOperators, false);
			resetValues(setFieldValue, setErrors, setFieldTouched);
			const unitStatus = getUnitStatus(
				recordTypeChoiceListFiles,
				recordType,
				values.field,
				configValue,
			);
			setUnitRequired(unitStatus);
		}
	};

	const setAdjustedFieldValue = (
		field: string,
		event: ChangeEvent<
			HTMLInputElementExtended | HTMLTextAreaElementExtended
		>,
		setFieldValue: (
			field: string,
			value: unknown,
			shouldValidate?: boolean,
		) => void,
	) => {
		const value = parseFloat(event.target.value);
		// const valueUnit = event.target.originalValue
		// 	? parseFloat(event.target.originalValue)
		// 	: undefined;

		// Some imperial conversions dont come back correctely and need an adjustment
		// if (valueUnit !== undefined && value != valueUnit) {
		// 	// mm -> in -> cm
		// 	if (fieldUnit === UNITS.MILLIMETER) value *= 10;
		// }

		setFieldValue(field, value);
	};

	const setYearFieldValue = (
		field: string,
		event: ChangeEvent<
			HTMLInputElementExtended | HTMLTextAreaElementExtended
		>,
		setFieldValue: (
			field: string,
			value: unknown,
			shouldValidate?: boolean,
		) => void,
	) => {
		let value = parseFloat(event.target.value);
		// Do this because smaller values i.e. 18 just get made into 1918
		if (isNaN(value) || value < 1900) value = 1899;
		const date = new Date(value, 1, 2, 12, 0);
		const dateString = date.toISOString();

		setFieldValue(field, dateString);
	};

	const [filtersConditionNotMet, setFiltersConditionNotMet] = useState(true); //controls when to enable save button
	//following function checks if any filters have been added to the query
	const checkFiltersForDefectPerUnitLength = () => {
		let filtersFound = false;
		if (allFilters && allFilters.length > 0)
			for (const filter of allFilters)
				if (
					filter.field &&
					['PACP_CODE', 'WSA_CODE'].includes(filter.field) &&
					filter?.value1
				)
					if (
						typeof filter.value1 === 'object' &&
						filter.value1.length > 0
					) {
						filtersFound = true;
						break;
					}
		return filtersFound;
	};
	//following useEffect is to enable/disable save btn when filters are added/removed from query
	useEffect(() => {
		const flag = checkFiltersForDefectPerUnitLength();
		setFiltersConditionNotMet(!flag);
	}, [allFilters]);

	const getFieldLabel = (field: string): string => {
		const selectedField = fields.find(value => value.value == field);
		return selectedField ? selectedField.label : field;
	};

	const generateValueField = (
		setFieldValue: (
			field: string,
			value: unknown,
			shouldValidate?: boolean,
		) => void,
		values: FormikValues,
		errors: FormikErrors<FormikValues>,
	) => {
		let valueFields = <></>;

		if (
			values.field.toLowerCase() == 'PACP_Code'.toLowerCase() ||
			values.field.toLowerCase() == 'WSA_Code'.toLowerCase()
		) {
			return (
				<Grid xs={12} item>
					<Autocomplete
						className={classes.defectRoot}
						classes={{
							tag: classes.codeListTag,
							option: classes.codeListOption,
						}}
						filterOptions={filterOptions}
						multiple
						id="defectCodes"
						freeSolo
						options={codeStrings}
						defaultValue={
							useDefault &&
							initialRuleValues.value1 &&
							Array.isArray(initialRuleValues.value1)
								? (initialRuleValues.value1 as string[])
								: []
						}
						getOptionLabel={option => {
							const defectcode = codes.find(
								code => code.code == option,
							);
							if (defectcode) return defectcode.desc;
							else return `${option}`;
						}}
						renderInput={params => (
							<TextField
								{...params}
								variant="outlined"
								label={t('Codes')}
							/>
						)}
						onChange={(_event, newValue) => {
							setFieldValue('value1', newValue, false);
						}}
						renderTags={(values, getTagProps) =>
							values.map((value, index) => (
								<Tooltip
									key={value as string}
									title={
										codes.find(code => code.code == value)
											?.desc ?? ''
									}>
									<Chip
										{...getTagProps({ index })}
										label={value as string}
									/>
								</Tooltip>
							))
						}
					/>
				</Grid>
			);
		}

		if (values.operator == 'BOOLEAN') {
			valueFields = (
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						error={errors && errors.value1 ? true : false}
						fullWidth>
						{inputField}
						{errors && errors.value1 && (
							<FormHelperText error={true}>
								{errors.value1}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			);

			return valueFields;
		} else if (
			(valueType === FIELDS_TYPES.NUMERICAL_ONE_FIELD ||
				valueType === FIELDS_TYPES.NUMERICAL_UNRESTRICTED_ONE_FIELD) &&
			unitRequired
		) {
			valueFields = (
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						error={errors && errors.value1 ? true : false}
						fullWidth>
						{fieldUnit ? (
							<UnitField
								fullWidth
								name="value1"
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElementExtended
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue(
										'value1',
										event,
										setFieldValue,
									);
								}}
								defaultValue={
									useDefault && initialRuleValues.value1
										? initialRuleValues.value1.toString()
										: ''
								}
								unit={fieldUnit}
								step={'any'}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors?.value1 ? true : false}
							/>
						) : (
							<TextField
								fullWidth
								name="value1"
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElementExtended
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue(
										'value1',
										event,
										setFieldValue,
									);
								}}
								defaultValue={
									useDefault && initialRuleValues.value1
										? initialRuleValues.value1.toString()
										: ''
								}
								type={'number'}
								variant="outlined"
								error={errors && errors?.value1 ? true : false}
							/>
						)}
						{errors && errors.value1 && (
							<FormHelperText error={true}>
								{errors.value1}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			);
			return valueFields;
		} else if (valueType === FIELDS_TYPES.CURRENCY_ONE_FIELD) {
			valueFields = (
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						error={errors && errors.value1 ? true : false}
						fullWidth>
						<CurrencyField
							fullWidth
							name="value1"
							onChange={(
								event: ChangeEvent<
									| HTMLInputElementExtended
									| HTMLTextAreaElementExtended
								>,
							) => {
								setAdjustedFieldValue(
									'value1',
									event,
									setFieldValue,
								);
							}}
							defaultValue={
								useDefault && initialRuleValues.value1
									? initialRuleValues.value1.toString()
									: ''
							}
							variant="outlined"
							inputProps={{
								inputMode: 'numeric',
								pattern: '[0-9]*',
							}}
							error={errors && errors?.value1 ? true : false}
						/>
						{errors && errors.value1 && (
							<FormHelperText error={true}>
								{errors.value1}
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			);
			return valueFields;
		} else if (
			(valueType === FIELDS_TYPES.NUMERICAL_TWO_FIELDS ||
				valueType === FIELDS_TYPES.NUMERICAL_UNRESTRICTED_TWO_FIELDS) &&
			unitRequired
		) {
			valueFields = (
				<>
					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value1 ? true : false}
							fullWidth>
							<UnitField
								fullWidth
								name="value1"
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue(
										'value1',
										event,
										setFieldValue,
									);
								}}
								defaultValue={
									useDefault && initialRuleValues.value1
										? initialRuleValues.value1.toString()
										: ''
								}
								unit={fieldUnit}
								step={'any'}
								label={t('Value 1')}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors.value1 ? true : false}
							/>
							{errors && errors.value1 && (
								<FormHelperText error={true}>
									{errors.value1}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value2 ? true : false}
							fullWidth>
							<UnitField
								fullWidth
								name="value2"
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue(
										'value2',
										event,
										setFieldValue,
									);
								}}
								defaultValue={
									useDefault && initialRuleValues.value2
										? initialRuleValues.value2.toString()
										: ''
								}
								unit={fieldUnit}
								step={'any'}
								label={t('Value 2')}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors.value2 ? true : false}
							/>
							{errors && errors.value2 && (
								<FormHelperText error={true}>
									{errors.value2}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				</>
			);
			return valueFields;
		} else if (valueType === FIELDS_TYPES.CURRENCY_TWO_FIELDS) {
			valueFields = (
				<>
					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value1 ? true : false}
							fullWidth>
							<CurrencyField
								fullWidth
								name="value1"
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue(
										'value1',
										event,
										setFieldValue,
									);
								}}
								defaultValue={
									useDefault && initialRuleValues.value1
										? initialRuleValues.value1.toString()
										: ''
								}
								label={t('Value 1')}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors.value1 ? true : false}
							/>
							{errors && errors.value1 && (
								<FormHelperText error={true}>
									{errors.value1}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value2 ? true : false}
							fullWidth>
							<CurrencyField
								fullWidth
								name="value2"
								onChange={(
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setAdjustedFieldValue(
										'value2',
										event,
										setFieldValue,
									);
								}}
								defaultValue={
									useDefault && initialRuleValues.value2
										? initialRuleValues.value2.toString()
										: ''
								}
								label={t('Value 2')}
								variant="outlined"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								error={errors && errors.value2 ? true : false}
							/>
							{errors && errors.value2 && (
								<FormHelperText error={true}>
									{errors.value2}
								</FormHelperText>
							)}
						</FormControl>
					</Grid>
				</>
			);
			return valueFields;
		} else if (valueType === FIELDS_TYPES.DATE_YEAR_ONE_FIELD) {
			valueFields = (
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						error={errors && errors.value1 ? true : false}
						fullWidth>
						<TextField
							fullWidth
							name="value1"
							onChange={(
								// eslint-disable-next-line prettier/prettier
								event: ChangeEvent<
									| HTMLInputElementExtended
									| HTMLTextAreaElementExtended
								>,
							) => {
								setYearFieldValue(
									'value1',
									event,
									setFieldValue,
								);
							}}
							defaultValue={
								useDefault && initialRuleValues.value1
									? new Date(
											(
												initialRuleValues.value1 as Array<any>
											)[0],
									  ).getFullYear()
									: ''
							}
							label={t('Year')}
							variant="outlined"
							type="number"
							inputProps={{
								min: 1900,
								max: 2200,
								step: 1,
							}}
							error={errors && errors.value1 ? true : false}
							helperText={(errors && errors.value1) ?? ''}
						/>
					</FormControl>
				</Grid>
			);
			return valueFields;
		} else if (valueType === FIELDS_TYPES.DATE_YEAR_TWO_FIELDS) {
			valueFields = (
				<>
					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value1 ? true : false}
							fullWidth>
							<TextField
								fullWidth
								name="value1"
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setYearFieldValue(
										'value1',
										event,
										setFieldValue,
									);
								}}
								defaultValue={
									useDefault && initialRuleValues.value1
										? new Date(
												(
													initialRuleValues.value1 as Array<any>
												)[0],
										  ).getFullYear()
										: ''
								}
								label={t('Year From')}
								variant="outlined"
								type="number"
								inputProps={{
									min: 1900,
									max: 2200,
									step: 1,
								}}
								error={errors && errors.value1 ? true : false}
								helperText={(errors && errors.value1) ?? ''}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							error={errors && errors.value2 ? true : false}
							fullWidth>
							<TextField
								fullWidth
								name="value2"
								onChange={(
									// eslint-disable-next-line prettier/prettier
									event: ChangeEvent<
										| HTMLInputElement
										| HTMLTextAreaElementExtended
									>,
								) => {
									setYearFieldValue(
										'value2',
										event,
										setFieldValue,
									);
								}}
								defaultValue={
									useDefault && initialRuleValues.value2
										? new Date(
												(
													initialRuleValues.value2 as Array<any>
												)[0],
										  ).getFullYear()
										: ''
								}
								label={t('Year To')}
								variant="outlined"
								type="number"
								inputProps={{
									min: 1900,
									max: 2200,
									step: 1,
								}}
								error={errors && errors.value2 ? true : false}
								helperText={(errors && errors.value2) ?? ''}
							/>
						</FormControl>
					</Grid>
				</>
			);
			return valueFields;
		}

		return inputField;
	};

	const getCurrentRule = (values: FormikValues) => {
		const allValues = [] as any;
		let dateValue2 = values.value2;
		if (values.value1 || values.value1 === 0) {
			if (
				valueType === FIELDS_TYPES.MULTI_SELECT_ONE_FIELD &&
				values.value1.length !== 0
			) {
				values.value1.map((val: any) => {
					if (typeof val === 'string') {
						allValues.push(val);
					} else {
						allValues.push(val.value);
					}
				});
			} else if (
				valueType === FIELDS_TYPES.DATE_ONE_FIELD ||
				valueType === FIELDS_TYPES.DATE_TWO_FIELDS
			) {
				//Date picker can only work with local datetime. This adjustment is
				//nneded to keep the date true and prevent from the selected date
				//from jumping backward and foward
				const jsDate = new Date(values.value1);
				let dt = DateTime.fromJSDate(jsDate);
				dt = dt.plus({ minutes: dt.offset });
				allValues.push(dt.toUTC().toISO());
				if (
					valueType === FIELDS_TYPES.DATE_TWO_FIELDS &&
					values.value2
				) {
					const jsDate2 = new Date(values.value2);
					let dt2 = DateTime.fromJSDate(jsDate2);
					dt2 = dt2.plus({ minutes: dt2.offset });
					dateValue2 = dt2.toUTC().toISO();
				}
			} else {
				if (isArray(values.value1) && values.value1.length > 0) {
					allValues.push(values.value1[0]);
				} else {
					allValues.push(values.value1);
				}
			}
		}
		const RuleObject: Rules = {
			id: uuid.v4(),
			recordType: values.recordType,
			config: recordTypeWithConfig ? values.config : '',
			field: values.field,
			operator: values.operator,
			value1: allValues,
			value2: dateValue2,
			unitLength: values.unitLength ?? null,
			ruleCondition: ruleCondition
				? ruleCondition
				: initialRuleValues.ruleCondition,
			unit: unitRequired ? (fieldUnit ? fieldUnit : values.unit) : '',
		};

		if (isFilter) {
			RuleObject.recordType = RECORD_TYPE.DEFECT_FILTER;
		}

		if (
			RuleObject.field &&
			(RuleObject.recordType === RECORD_TYPE.USER_DEFINED_FIELDS ||
				RuleObject.recordType === RECORD_TYPE.RISK ||
				RuleObject.recordType === RECORD_TYPE.TASK)
		) {
			const fieldsData = getFieldsData(
				recordTypeChoiceListFiles,
				RuleObject.recordType,
				RuleObject.config,
			);
			if (fieldsData && RuleObject.operator) {
				const ruleOptions: RuleOptions = {
					valueType: valueType
						? valueType
						: fieldsData[RuleObject.field][0]['operators'][
								RuleObject.operator
						  ]['valueType'],
					type: fieldsData[RuleObject.field][0]['type'],
				};
				RuleObject.options = ruleOptions;
			}
		}
		return RuleObject;
	};

	const handleSubmit = (values: FormikValues) => {
		const RuleObject = getCurrentRule(values);
		addRuleToQuery(RuleObject);
		setQueryPanelDisabledState(false);
		if (formRef && formRef.current) formRef.current.resetForm();

		setFieldUnit('');
	};

	const showFilter = !isFilter && allowFilter;
	const showFilterNum =
		!isFilter &&
		allowFilter &&
		((initialRuleValues.filters && initialRuleValues.filters.length > 0) ||
			allFilters?.length > 0);

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
	});

	return (
		<Grid xs={6} item>
			<Wrapper>
				<Grid xs={12} item>
					<Text variant={'heading-medium'}>
						{isFilter ? t('Filters') : t('Rule Configuration')}
					</Text>
				</Grid>

				<Formik
					initialValues={initialRuleValues}
					enableReinitialize={true}
					validateOnChange={true}
					validationSchema={
						unitRequired
							? validationSchema /* validationSchema.shape({
									unit: yup.number().required(),
							  })*/
							: validationSchema
					}
					innerRef={formRef}
					onSubmit={handleSubmit}>
					{({
						values,
						errors,
						isSubmitting,
						setFieldValue,
						setErrors,
						submitForm,
						setFieldTouched,
					}) => {
						return (
							<FullHeight>
								<Content>
									<StyledForm autoComplete="off">
										<Grid container spacing={2}>
											{isFilter ? null : (
												<Grid item xs={12}>
													<FormControl
														variant="outlined"
														error={
															errors.recordType
																? true
																: false
														}
														fullWidth>
														<InputLabel
															htmlFor="outputType-select"
															id="outputType-label">
															{t('Record Type')}
														</InputLabel>

														<Select
															labelId="outputType-label"
															id="outputType"
															value={
																values.recordType
															}
															label={t(
																'Record Type',
															)}
															onChange={(
																event: SelectChangeEvent<string>,
															) =>
																handleOutputTypeChange(
																	event,
																	setFieldValue,
																)
															}>
															{recordTypeOptionsFlagged().map(
																({
																	label,
																	value,
																}) => (
																	<MenuItem
																		key={
																			value
																		}
																		value={
																			value
																		}>
																		{label}
																	</MenuItem>
																),
															)}
														</Select>
														{errors?.recordType && (
															<FormHelperText>
																Record type is
																required
															</FormHelperText>
														)}
													</FormControl>
												</Grid>
											)}

											{isFilter ||
											(values.recordType &&
												!errors.recordType &&
												!choiceListIsLoading) ? (
												<>
													{recordTypeWithConfig && (
														<Grid item xs={12}>
															<FormControl
																variant="outlined"
																error={
																	errors.config ||
																	(values.field &&
																		!values.config)
																		? true
																		: false
																}
																fullWidth>
																<InputLabel
																	htmlFor="config-select"
																	id="config-label">
																	{t(
																		`${configFieldData.label}`,
																	)}
																</InputLabel>

																<Select
																	labelId="config-label"
																	id="config"
																	value={
																		values.config
																	}
																	label={t(
																		`${configFieldData.label}`,
																	)}
																	onChange={(
																		event: SelectChangeEvent<string>,
																	) =>
																		handleConfigChange(
																			event,
																			setFieldValue,
																		)
																	}>
																	{configFieldData.data.map(
																		({
																			label,
																			value,
																		}) => (
																			<MenuItem
																				key={
																					value
																				}
																				value={
																					value
																				}>
																				{
																					label
																				}
																			</MenuItem>
																		),
																	)}
																</Select>
																{errors?.recordType ||
																	(values.field &&
																		!values.config && (
																			<FormHelperText>
																				Config
																				is
																				required
																			</FormHelperText>
																		))}
															</FormControl>
														</Grid>
													)}
													{(!recordTypeWithConfig ||
														values.config) && (
														<Grid item xs={12}>
															<FormControl
																variant="outlined"
																error={
																	errors.field
																		? true
																		: false
																}
																fullWidth>
																<InputLabel
																	htmlFor="field-select"
																	id="field-label">
																	{t('Field')}
																</InputLabel>

																<Select
																	labelId="field-label"
																	id="field"
																	value={
																		values.field
																	}
																	label={t(
																		'Field',
																	)}
																	onChange={(
																		event: SelectChangeEvent<unknown>,
																	) =>
																		handleFieldChange(
																			event,
																			setFieldValue,
																			values,
																		)
																	}>
																	{fields.map(
																		({
																			label,
																			value,
																		}) => (
																			<MenuItem
																				key={
																					value
																				}
																				value={
																					value
																				}
																				disabled={isDisabled(
																					value,
																				)}>
																				{
																					label
																				}
																			</MenuItem>
																		),
																	)}
																</Select>
																{errors?.field && (
																	<FormHelperText>
																		{t(
																			'Field is required',
																		)}
																	</FormHelperText>
																)}
															</FormControl>
															{values.field ===
																'DEFECT_COUNT_UNIT_LENGTH' && (
																<FormControl
																	style={{
																		marginTop:
																			'16px',
																	}}
																	variant="outlined"
																	error={
																		errors &&
																		errors.unitLength
																			? true
																			: false
																	}
																	fullWidth>
																	<UnitField
																		fullWidth
																		name="unitLength"
																		label={t(
																			'Unit length',
																		)}
																		min={1}
																		onChange={event => {
																			setAdjustedFieldValue(
																				'unitLength',
																				event,
																				setFieldValue,
																			);
																		}}
																		onBlur={event => {
																			setAdjustedFieldValue(
																				'unitLength',
																				event,
																				setFieldValue,
																			);
																		}}
																		defaultValue={
																			useDefault &&
																			initialRuleValues.unitLength
																				? initialRuleValues.unitLength.toString()
																				: ''
																		}
																		unit={
																			'm'
																		}
																		step={
																			'any'
																		}
																		variant="outlined"
																		inputProps={{
																			inputMode:
																				'numeric',
																			pattern:
																				'[0-9]*',
																		}}
																		error={
																			errors &&
																			errors?.unitLength
																				? true
																				: false
																		}
																	/>
																	{errors &&
																		errors.unitLength && (
																			<FormHelperText
																				error={
																					true
																				}>
																				{
																					errors.unitLength
																				}
																			</FormHelperText>
																		)}
																</FormControl>
															)}
														</Grid>
													)}
												</>
											) : null}
											{!isFilter &&
											values.recordType &&
											!errors.recordType &&
											choiceListIsLoading ? (
												<LoadingPage
													size="sm"
													title={t(
														'Loading field options..',
													)}
												/>
											) : null}

											{values.field &&
												fields.length > 0 &&
												(!recordTypeWithConfig ||
													values.config) && (
													<>
														<Grid
															className={
																classes.operatorSection
															}
															item
															xs={12}>
															<Text
																variant={
																	'heading-medium'
																}>
																{t(
																	'{{values}} Options',
																	{
																		values: getFieldLabel(
																			values.field,
																		),
																	},
																)}
															</Text>
														</Grid>

														<Grid
															item
															xs={12}
															className={
																classes.newRuleNote
															}>
															<InfoOutlined
																className={
																	classes.newRuleNoteIcon
																}
															/>
															<Typography
																className={
																	classes.newRuleNoteText
																}
																variant="caption">
																{t(
																	'Allows you to query ',
																)}
																<span
																	className={
																		classes.newRuleNoteColor
																	}>
																	{t(
																		getFieldLabel(
																			values.field,
																		),
																	)}
																</span>
																{values.field ===
																	'DEFECT_COUNT_UNIT_LENGTH' && (
																	<div>
																		{t(
																			'You must add at least one code filter for this query',
																		)}
																	</div>
																)}
															</Typography>
														</Grid>

														<Grid item xs={12}>
															<FormControl
																variant="outlined"
																error={
																	errors.operator &&
																	!values.operator
																		? true
																		: false
																}
																fullWidth>
																<InputLabel
																	htmlFor="operator-select"
																	id="operator-label">
																	{t(
																		'Operator',
																	)}
																</InputLabel>

																<Select
																	labelId="operator-label"
																	id="operator"
																	name="operator"
																	value={
																		values.operator
																	}
																	label={t(
																		'Operator',
																	)}
																	onChange={(
																		event: SelectChangeEvent<unknown>,
																	) =>
																		handleOperatorChange(
																			event,
																			setFieldValue,
																			values,
																			setErrors,
																			setFieldTouched,
																		)
																	}>
																	{operators.map(
																		({
																			label,
																			value,
																		}) => (
																			<MenuItem
																				key={
																					value
																				}
																				value={
																					value
																				}>
																				{
																					label
																				}
																			</MenuItem>
																		),
																	)}
																</Select>
																{errors?.operator &&
																	!values.operator && (
																		<FormHelperText>
																			{t(
																				'Operator is required',
																			)}
																		</FormHelperText>
																	)}
															</FormControl>
														</Grid>
													</>
												)}

											{values.operator &&
												generateValueField(
													setFieldValue,
													values,
													errors,
												)}
										</Grid>
									</StyledForm>
								</Content>

								{showFilterNum ? (
									<Grid
										container
										className={classes.ActionDiv}>
										<Grid item xs={4}>
											<Button
												className={
													classes.LeftFilterNumButton
												}
												disableElevation
												disableRipple
												variant="contained"
												data-cy="FilterNum">
												{t(
													'{{num}} {{filterText}} Added',
													{
														num: allFilters?.length,
														filterText:
															allFilters?.length >
															1
																? 'Filters'
																: 'Filter',
													},
												)}
											</Button>
										</Grid>
									</Grid>
								) : null}

								<Grid container className={classes.ActionDiv}>
									<Grid item xs={6}>
										{showFilter ? (
											<Button
												onClick={() => {
													setTempRule(
														getCurrentRule(values),
													);

													openFilterHandler();
												}}
												variant="contained"
												data-cy="Filters">
												{t('Open Filters')}
											</Button>
										) : null}
									</Grid>
									<Grid
										item
										xs={6}
										style={{ textAlign: 'right' }}>
										<Button
											onClick={() => {
												onCancelRule();
												setQueryPanelDisabledState(
													false,
												);
												if (formRef && formRef.current)
													formRef.current.resetForm();
											}}
											variant="contained"
											color="secondary"
											data-cy="Cancel">
											{t('Cancel')}
										</Button>
										<Button
											style={{ marginLeft: '0.5rem' }}
											color="primary"
											disabled={
												isSubmitting ||
												(values.field ===
													'DEFECT_COUNT_UNIT_LENGTH' &&
													filtersConditionNotMet)
											}
											onClick={submitForm}
											variant="contained"
											data-cy="Ok">
											{isFilter
												? t('Add Filter')
												: t('Save')}
										</Button>
									</Grid>
								</Grid>
							</FullHeight>
						);
					}}
				</Formik>
			</Wrapper>
		</Grid>
	);
};

export default RuleConfiguration;
