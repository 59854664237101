export enum MEASUREMENT_SYSTEM {
	METRIC = 'Metric',
	IMPERIAL = 'Imperial',
}

export enum SYSTEM_UNIT_TYPE {
	LENGTH_LONG = 'LengthLong',
	LENGTH = 'Length',
	VOLUME = 'Volume',
	PRESSURE = 'Pressure',
	FLOW = 'Flow',
	ANGLE = 'Angle',
	AREA = 'Area',
	POWER = 'Power',
	ROTATION = 'Rotation',
	VELOCITY = 'Velocity',
	ELECTRIC_CURRENT = 'ElectricCurrent',
}

export enum UNITS {
	DEGREE = 'degree',
	CUBIC_METER_PER_SECOND = 'm3/s',
	CUBIC_FEET_PER_SECOND = 'ft3/s',
	MILLIMETER = 'mm',
	METER = 'm',
	METER_OF_HEAD = 'mH2O',
	CUBIC_METER = 'm3',
	FOOT = 'foot',
	INCH = 'inch',
	PSI = 'psi',
	METER_SQUARE = 'm2',
	KILO_WATT = 'kw',
	REVOLUTIONS_PER_MIN = 'rpm',
	METER_PER_SECOND = 'm/s',
	LITRE_PER_SECOND = 'l/s',
	CUBIC_FEET = 'ft3',
	FEET_SQUARE = 'ft2',
	FEET_PER_SECOND = 'ft/s',
}

export const assetFieldsSystemUnitType: any = {
	'Sanitary Sewer': {
		pipe: {
			LENGTH: 'LengthLong',
			CALCULATED_BURIED_DEPTH: 'LengthLong',
			CAPACITY: 'Flow',
			DS_DEPTH_FROM_COVER: 'LengthLong',
			US_DEPTH_FROM_COVER: 'LengthLong',
			DS_INVERT: 'LengthLong',
			US_INVERT: 'LengthLong',
			GRADIENT: 'Length',
			HEIGHT: 'Length',
			WIDTH: 'Length',
			DEFECT_COUNT_UNIT_LENGTH: 'LengthLong',
		},
	},
	'Water Distribution': {
		pipe: {
			LENGTH: 'LengthLong',
			DEPTH_OF_COVER: 'Length',
			EXTERNAL_DIAMETER: 'Length',
			INTERNAL_DIAMETER: 'Length',
			CAPACITY: 'Flow',
			MAX_VELOCITY: 'Velocity',
			MIN_VELOCITY: 'Velocity',
			NOMINAL_DIAMETER: 'Length',
			PRESSURE_RATING: 'Pressure',
			DEFECT_COUNT_UNIT_LENGTH: 'LengthLong',
		},
	},
};

export const inspectionFieldsSystemUnitType: { [key: string]: string } = {
	HEIGHT: 'Length',
	WIDTH: 'Length',
	SURVEY_LENGTH: 'LengthLong',
	DEFECT_COUNT_UNIT_LENGTH: 'LengthLong',
};

export const wsa05InspectionFieldsSystemUnitType: { [key: string]: string } = {
	ACTUAL_INSPECTION_LENGTH: 'LengthLong',
	ANTICIPATED_LENGTH_OF_INSPECTION: 'LengthLong',
	CONDUIT_UNIT_LENGTH: 'LengthLong',
	DEPTH_AT_FINISH_NODE: 'LengthLong',
	DEPTH_AT_START_NODE: 'LengthLong',
	EVIDENCE_OF_SURCHARGE: 'LengthLong',
	HEIGHT: 'Length',
	WIDTH: 'Length',
	DEFECT_COUNT_UNIT_LENGTH: 'LengthLong',
};

export const defectUnitType: { [key: string]: string } = {
	DISTANCE: 'LengthLong',
};
