import {
	selectRehabActions,
	selectRehabResultFilter,
	selectRehabResultIsLoading,
	selectRehabResultIsWaiting,
	selectRehabResultQuery,
	selectRehabResultRows,
	selectRehabResultSearch,
	selectRehabResultTablePage,
	selectorRehabResultsPagination,
} from '@Selectors/Rehab.selectors';
import {
	getTenant,
	useSelectAssetLocation,
	useSelectAssetLocationIsWaiting,
} from '@innovyze/lib-am-common/Selectors';
import { fullAccess } from '@innovyze/shared-utils';
import {
	GridColumnVisibilityModel,
	getSubscriptions,
	useSelectUserContext,
	useUnits,
} from '@innovyze/stylovyze';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const initialColumnVisibilityModel: GridColumnVisibilityModel = {
	__reorder__: false,
};

export function useRehabResultSelectors() {
	const { system } = useUnits();

	const tablePage = selectRehabResultTablePage(system);
	const subscriptions = getSubscriptions();
	const hasFullAccess = fullAccess(subscriptions);
	const isLoading = selectRehabResultIsLoading();
	const queryString = selectRehabResultQuery();
	const isWaitingRehab = selectRehabResultIsWaiting();
	const isWaitingAssetLocation = useSelectAssetLocationIsWaiting();
	const rehabRows = selectRehabResultRows();
	const rehabActions = selectRehabActions();
	const assetLocation = useSelectAssetLocation();
	const tenantId = useSelector(getTenant);
	const { id: userId } = useSelectUserContext();
	const rehabResultsPagination = useSelector(selectorRehabResultsPagination);
	const search = selectRehabResultSearch();
	const filter = selectRehabResultFilter();

	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({
			__reorder__: false,
		});

	const resetColumnVisibilityModel = () => {
		setColumnVisibilityModel(initialColumnVisibilityModel);
	};

	const isWaiting = isWaitingRehab || isWaitingAssetLocation;

	return {
		tablePage,
		hasFullAccess,
		isLoading,
		queryString,
		isWaiting,
		rehabRows,
		rehabActions,
		assetLocation,
		tenantId,
		userId,
		rehabResultsPagination,
		search,
		columnVisibilityModel,
		setColumnVisibilityModel,
		filter,
		resetColumnVisibilityModel,
	};
}
