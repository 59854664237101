import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';
import { RehabQueryChoiceList } from '@Types';
import { TFunc } from '@Translations/types';

export const populateFieldOptions = createAction<{ fieldOptions: [] }>(
	addNamespace('choiceList/populateFieldOptions'),
);

export const getAllRehabRuleChoiceListFiles = createAction<{
	systemType?: string;
	assetType?: string;
	inspectionStandard?: string;
	t: TFunc;
	shouldShowFullDefects: boolean;
}>(addNamespace('choiceList/getAllRehabRuleChoiceListFiles'));

export const getAllRehabRuleChoiceListFilesResolved =
	createAction<RehabQueryChoiceList>(
		addNamespace('choiceList/getAllRehabRuleChoiceListFilesResolved'),
	);

export const getAllRehabRuleChoiceListFilesRejected = createAction(
	addNamespace('choiceList/getAllRehabRuleChoiceListFilesRejected'),
);
