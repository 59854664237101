import {
	AssetType,
	ColumnFilterValue,
	Sort2 as Sort,
} from '@innovyze/lib-am-common';
import {
	getAssetDetail,
	getAssetLocation,
	getAssetRisk,
	getAssetRiskHistory,
	getInspectionAttributes,
} from '@innovyze/lib-am-common/Actions';
import { makeMapURL } from '@innovyze/lib-am-common/Utils';
import {
	CurrencyDisplay,
	DataGrid,
	GridColDef,
	GridFilterModel,
	GridLogicOperator,
	GridRenderCellParams,
	GridRowsProp,
	GridSortModel,
	MenuListButton,
	getGridSingleSelectOperators,
	openDrawer,
	useGridApiRef,
	useIsFeatureEnabled,
	useSettings,
	useTzDateTime,
} from '@innovyze/stylovyze';
import { Edit, Lock, LockOpen, Map } from '@mui/icons-material';
import { Link } from '@mui/material';
import qs from 'query-string';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	changePage,
	changeSearch,
	changePagination,
	changeSort,
	lockAction,
	reloadRehabResult,
	changeFiltersSelect,
	clearScanId,
	changeRehabResultsFilter,
} from '@Actions';
import { useGlobalization } from '@Translations';
import { Option, RehabResults } from '@Types';
import { ROWS_PER_PAGE } from '@Utils/constants';
import { RehabIcon } from './../../pages/RehabIcon';
import { AssetDetailActions } from './RehabResult.component';
import { useRehabResultSelectors } from './useRehabResultsSelectors';
import { selectRehabResultFilterProps } from '@Selectors/Rehab.selectors';
import { SystemTypes } from '@innovyze/inno-map';

interface AssetIdAndType {
	assetId: string;
	assetType: AssetType;
}

interface RehabResultTableProps {
	readonly assetType: AssetType;
	readonly treeId: string;
	readonly assetAction?: AssetDetailActions;
	readonly viewerAccess: boolean;
	readonly setRehabResultToEdit: React.Dispatch<
		React.SetStateAction<RehabResults | null | undefined>
	>;
}

export const removeInvalidEntries = (filterModel: GridFilterModel) => {
	const items = filterModel?.items?.filter(
		item =>
			(item.value && item.value.length !== 0) ||
			((item.operator == 'isEmpty' || item.operator == 'isNotEmpty') &&
				!item.value),
	);
	return {
		...filterModel,
		items,
	};
};

export default function RehabResultTable({
	assetType,
	treeId,
	assetAction,
	viewerAccess,
	setRehabResultToEdit,
}: RehabResultTableProps) {
	// library hooks
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const history = useHistory();
	const filters = selectRehabResultFilterProps();
	const apiRef = useGridApiRef();

	// custom hooks
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const { companySettings } = useSettings();

	// feature flags
	const isFindAssetsEnabled = !!useIsFeatureEnabled(
		'info-360-navigate-to-asset',
	);

	// selectors
	const {
		tablePage,
		hasFullAccess,
		isLoading,
		queryString,
		isWaiting,
		rehabRows,
		rehabActions,
		assetLocation,
		rehabResultsPagination,
		search,
		filter,
		columnVisibilityModel,
		setColumnVisibilityModel,
	} = useRehabResultSelectors();

	const paginationModel = React.useMemo(() => {
		return {
			page: (rehabResultsPagination.page ?? 1) - 1,
			pageSize: rehabResultsPagination.slice ?? 10,
		};
	}, [rehabResultsPagination]);

	const [filterModel, setFilterModel] = React.useState<
		GridFilterModel | undefined
	>(filter);

	const [sortModel, setSortModel] = React.useState<GridSortModel>([
		{
			field: 'ASSET_ID',
			sort: tablePage.sortDescending ? 'desc' : 'asc',
		},
	]);

	const [findAsset, setFindAsset] = React.useState<
		AssetIdAndType | undefined
	>(undefined);
	const lockedCell = (locked: string) => {
		if (locked === '1') {
			return <Lock style={{ color: '#768D95' }} />;
		} else {
			return <LockOpen style={{ color: '#768D95' }} />;
		}
	};

	/*
	 * This useEffect hook navigates to a new URL when `assetLocation` or `findAsset` changes.
	 * The URL is generated based on the asset's type, id, and position.
	 * After navigation, it resets `findAsset` to its initial state.
	 */
	React.useEffect(() => {
		if (
			findAsset &&
			assetLocation &&
			assetLocation.assetId === findAsset.assetId
		) {
			const url = makeMapURL(
				findAsset.assetType,
				findAsset.assetId,
				assetLocation.position,
			);

			if (url) history.push(url);

			setFindAsset(undefined);
		}
	}, [assetLocation, findAsset]);

	React.useEffect(() => {
		setFilterModel(filter);
		if (filter === undefined) {
			apiRef.current.setFilterModel({
				items: [],
				logicOperator: GridLogicOperator.And,
			});
		}
	}, [filter]);

	// actions
	const dispatchSearchFn = (text: string) => dispatch(changeSearch(text));
	const dispatchSortFn = (name: string, desc: boolean) => {
		const sortPayload: Sort = {
			key: name,
			descending: desc,
		};
		dispatch(changeSort(sortPayload));
	};

	const handleOpen = (assetId: string, assetType: string) => {
		if (assetAction?.onAssetDetailClicked) {
			assetAction.onAssetDetailClicked(assetId, assetType);

			const systemType = assetType.startsWith('ww')
				? SystemTypes.SanitarySewer
				: SystemTypes.WaterDistribution;

			dispatch(
				getAssetDetail({
					assetId: assetId,
					assetType: assetType,
					formatDateUTC: formatDateUTC,
					systemType: systemType,
				}),
			);

			dispatch(
				getAssetRisk({
					assetId: assetId,
					assetType: assetType,
				}),
			);

			dispatch(
				getAssetRiskHistory({
					assetId: assetId,
					assetType: assetType,
				}),
			);

			dispatch(
				getInspectionAttributes({
					assetId: assetId,
					assetType: assetType,
				}),
			);

			dispatch(
				getInspectionAttributes({
					assetId: assetId,
					assetType: assetType,
				}),
			);
		}
	};
	const rowMenuItems = (row: string[], index: number) => {
		const result = new Array<Option>();
		if (row && row.length > 0 && row[0] == '1') {
			result.push({
				text: t('Unlock'),
				name: 'Unlock',
				icon: <LockOpen />,
				onClick: () => {
					if (row != null && row != undefined) {
						const rehabResults: RehabResults = {
							treeId: treeId,
							assetId: row[1],
							locked: '0',
						};
						dispatch(lockAction(rehabResults));
					}
				},
				cy: `${index}-dropdown-unlock`,
			});
		} else {
			result.push({
				text: t('Edit'),
				name: 'Edit',
				icon: <Edit />,
				onClick: () => {
					if (
						index != null &&
						index != undefined &&
						rehabRows &&
						rehabRows.length > index
					) {
						const rehabRow = rehabRows[index];
						const rehabResults: RehabResults = {
							treeId: treeId,
							assetId: row[1],
							totalCost: rehabRow.TOTAL_COST
								? +rehabRow.TOTAL_COST
								: 0,
							costCalculation: row[4],
							locked: row[0],
							modifiedBy: rehabRow.MODIFIED_BY,
							draftRehabAction: row[5],
							actionNodeId: rehabRows[index].ACTION_NODE_ID,
							notes: rehabRow.NOTES,
						};
						if (
							rehabRow?.REHAB_ACTION &&
							rehabRow?.REHAB_ACTION != ''
						) {
							try {
								rehabResults.rehabAction = JSON.parse(
									rehabRow.REHAB_ACTION,
								);
							} catch (e) {
								console.log(e);
							}
						} else {
							rehabResults.rehabAction = rehabActions.find(
								rehabAction => {
									return rehabAction.actionId == row[2];
								},
							);
						}
						setRehabResultToEdit(rehabResults);
						dispatch(openDrawer('editRehabResultDrawer'));
					}
				},
				cy: `${index}-dropdown-edit`,
			});
			result.push({
				text: t('Lock'),
				name: 'Lock',
				icon: <Lock />,
				onClick: () => {
					if (index != null && index != undefined) {
						if (row != null && row != undefined) {
							const rehabResults: RehabResults = {
								treeId: treeId,
								assetId: row[1],
								locked: '1',
							};
							dispatch(lockAction(rehabResults));
						}
					}
				},
				cy: `${index}-dropdown-lock`,
			});
		}

		if (isFindAssetsEnabled) {
			result.push({
				text: t('Find Asset in Network'),
				name: 'Find',
				icon: <Map />,
				onClick: () => {
					if (index != null && index != undefined) {
						if (row != null && row != undefined) {
							const mapAsset = {
								assetId: row[1],
								assetType: assetType,
							};
							setFindAsset(mapAsset);

							dispatch(getAssetLocation(mapAsset));
						}
					}
				},
			});
		}

		return result;
	};
	const menuCell = (row: string[], index: number) => {
		if (!viewerAccess) {
			return (
				<div
					role="menu"
					tabIndex={0}
					className={isLoading ? 'hidden' : ''}>
					<MenuListButton menuItems={rowMenuItems(row, index)} />
				</div>
			);
		}
	};

	// derived values
	const requestParams = {
		...qs.parse(queryString.substring(1)),
		slice: '100000',
		treeId,
		companySettings,
	};

	const columns: GridColDef[] = [
		{
			field: 'Locked',
			headerName: '',
			width: 50,
			pinnable: false,
			type: 'singleSelect',
			filterable: true,
			sortable: false,
			filterOperators: getGridSingleSelectOperators().filter(
				operator => operator.value === 'is',
			),
			headerAlign: 'center',
			disableColumnMenu: true,
			valueOptions: filters[1].options
				.map(filter => ({
					value: filter.id,
					label: filter.caption,
				}))
				.filter(option => option.label !== 'false'),
			renderCell: (params: GridRenderCellParams) =>
				lockedCell(params.row.locked + ''),
		},
		{
			field: 'ASSET_ID',
			headerName: t('Asset ID'),
			renderCell: (params: GridRenderCellParams) => (
				<Link
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						cursor: 'pointer',
					}}
					title={params.value}
					onClick={() => {
						handleOpen(params.value as string, assetType);
					}}>
					{params.value}
				</Link>
			),
			flex: 1,
			filterable: false,
			width: 100,
			pinnable: false,
		},
		{
			field: 'FINAL_RRHAB_ACTION',
			headerName: t('Rehab Action'),
			align: 'left',
			headerAlign: 'left',
			type: 'singleSelect',
			filterOperators: getGridSingleSelectOperators().filter(
				operator => operator.value === 'isAnyOf',
			),
			valueOptions: filters[0].options.map(filter => ({
				value: filter.id,
				label: filter.caption,
			})),
			filterable: true,
			sortable: false,
			pinnable: false,
			renderCell: (params: GridRenderCellParams) => params.value,
		},
		{
			field: 'TOTAL_COST',
			headerName: t('Total Cost'),
			renderCell: (params: GridRenderCellParams) => (
				<CurrencyDisplay value={params.value as string} />
			),
			align: 'left',
			headerAlign: 'left',
			filterable: false,
			pinnable: false,
			flex: 1,
		},
		{
			field: 'COST_CALCULATION',
			headerName: t('Cost Calculation'),
			flex: 1,
			pinnable: false,
			filterable: false,
		},
		{
			field: 'ACTION_NAME',
			headerName: t('Draft Rehab Action'),
			filterable: false,
			sortable: true,
			width: 150,
			pinnable: false,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'Menu',
			align: 'center',
			headerAlign: 'center',
			width: 68,
			pinnable: false,
			headerName: '',
			renderCell: (params: GridRenderCellParams) => {
				return menuCell(
					[
						params.row.locked,
						params.row.ASSET_ID,
						params.row.FINAL_RRHAB_ACTION,
						params.row.TOTAL_COST,
						params.row.COST_CALCULATION,
						params.row.ACTION_NAME,
					],
					params.row.id,
				);
			},
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
		},
	];

	const rows: GridRowsProp =
		tablePage.cells.map((row: string[], nRow: number) => {
			return {
				id: nRow,
				locked: row[0],
				ASSET_ID: row[1],
				FINAL_RRHAB_ACTION: row[2],
				TOTAL_COST: row[3],
				COST_CALCULATION: row[4],
				ACTION_NAME: row[5],
			};
		}) || [];

	const initialSortModel: GridSortModel = React.useMemo(() => {
		if (rehabResultsPagination && rehabResultsPagination.sortKey)
			return [
				{
					field: rehabResultsPagination.sortKey,
					sort: rehabResultsPagination.sortDescending
						? 'desc'
						: 'asc',
				},
			];
		else
			return [
				{
					// Default sort is ASSET_ID asc
					field: 'ASSET_ID',
					sort: 'asc',
				},
			];
	}, [rehabResultsPagination]);

	return (
		<DataGrid
			apiRef={apiRef}
			weave={true}
			columns={columns}
			initialState={{
				pagination: {
					paginationModel: paginationModel,
				},
				sorting: {
					sortModel: initialSortModel,
				},
				filter: {
					filterModel: filterModel,
				},
			}}
			rows={rows}
			data-cy="risk-details-table"
			pinnedColumns={{ left: ['Locked', 'ASSET_ID'], right: ['Menu'] }}
			className={isWaiting ? 'wait' : 'ScrollTable'}
			// header settings
			refreshButton={{
				onRefresh: () => {
					dispatch(clearScanId());
					dispatch(changePage(tablePage.page));
				},
			}}
			export={
				hasFullAccess
					? {
							total: tablePage.total,
							requestParams: requestParams as Record<string, any>,
							disabled: isLoading || isWaiting,
							source: 'rehab.results.export',
							title: t('Export'),
							maxRows: 100000,
							dialogDataCy: 'export-dialog',
							iconDataCy: 'export-icon',
							dataCy: 'export-button',
							maxPollAttempts: 60,
							iterationTime: 5000,
					  }
					: undefined
			}
			filterToolbar
			filterMode="server"
			onFilterModelChange={(model: GridFilterModel) => {
				// remove the invalid filters as the filter model change event
				// is triggered when the modal is poppped up
				const validFilter = removeInvalidEntries(model);
				if (
					// first time filter modal is intialised check there are valid filters to send
					(!filterModel && validFilter?.items.length) ||
					// afterwards check if the filter has changed before sending
					(filterModel &&
						JSON.stringify(filterModel) !==
							JSON.stringify(validFilter))
				) {
					const isLocked = () => {
						const lockedItems = validFilter.items
							.filter(item => item.field === 'Locked')
							.map(item => item.value);

						if (lockedItems.includes('false')) return false;
						if (lockedItems.includes('true')) return true;

						return undefined;
					};

					const finalRehabs = validFilter.items
						?.filter(item => item.field === 'FINAL_RRHAB_ACTION')
						?.reduce((acc: string[], val) => {
							if (val?.value) acc.push(...val?.value);
							return acc;
						}, [])
						// remove duplicates
						?.filter(
							(value, index, self) =>
								self.indexOf(value) === index,
						);

					const filter: ColumnFilterValue[] = [
						{
							colKey: 'FINAL_RRHAB_ACTION',
							value: finalRehabs,
						},
					];

					const locked = isLocked();
					if (locked !== undefined) {
						filter.push({
							colKey: 'LockedOnly',
							value: locked ? 'true' : 'false',
						});
					}
					dispatch(changeFiltersSelect(filter));
					dispatch(changeRehabResultsFilter(validFilter));
				}
			}}
			search={{
				dataCy: 'search-01',
				onChange: query => {
					dispatchSearchFn(query);
					dispatch(reloadRehabResult());
				},
				onRequestSearch: () => {
					dispatch(reloadRehabResult());
				},
				onCancelSearch: () => {
					dispatchSearchFn('');
					dispatch(reloadRehabResult());
				},
				debounce: true,
				placeholder: t('Search'),
				searchInProgress: isLoading,
				value: search,
			}}
			loading={isLoading || isWaiting}
			skeletonRowCount={paginationModel.pageSize}
			footerHelper={
				isLoading
					? ''
					: t('{{from}}-{{to}} of {{total}}', {
							from:
								(rehabResultsPagination.page - 1) *
									rehabResultsPagination.slice +
								1,
							to:
								rehabResultsPagination.page ==
								rehabResultsPagination.pages
									? rehabResultsPagination.total
									: rehabResultsPagination.page *
									  rehabResultsPagination.slice,
							total: rehabResultsPagination.total,
					  })
			}
			emptyState={{
				title: 'There are no rehab results',
				subtitle:
					'Rehab results are generated based on the inspection results and the rehab actions defined in the system.',
				icon: <RehabIcon />,
				color: '#4A6067',
			}}
			disableMultipleColumnsSorting
			disableColumnReorder
			// pagination and rows per page settings
			paginationMode="server"
			paginationModel={paginationModel}
			onPaginationModelChange={_paginationModel => {
				if (paginationModel.page === _paginationModel.page) {
					// paginationModel didn't change, so we need to reset the page to 0
					// this way the table will start from the first page
					_paginationModel.page = 0;
				}
				dispatch(
					changePagination({
						page: _paginationModel.page + 1,
						slice: _paginationModel.pageSize,
					}),
				);
			}}
			rowCount={tablePage.total}
			pagination
			pageSizeOptions={ROWS_PER_PAGE}
			sortingOrder={['desc', 'asc']}
			sortingMode="server"
			onSortModelChange={sm => {
				if (!sm.length) {
					if (!sortModel.length) {
						return sm.push({ field: 'ASSET_ID', sort: 'asc' });
					} else {
						const sortedField = sortModel?.[0];
						sortedField.sort =
							sortedField.sort === 'asc' ? 'desc' : 'asc';
						sm.push(sortModel[0]);
					}
				}

				sm.forEach(sort => {
					const key = sort?.field;
					const descending = sort?.sort !== 'asc';
					dispatchSortFn(key, descending);
				});

				if (!sm.length) dispatchSortFn('ASSET_ID', false);
				setSortModel(sm);
			}}
			slotProps={{
				filterPanel: {
					logicOperators: [GridLogicOperator.And],
				},
			}}
			onColumnVisibilityModelChange={setColumnVisibilityModel}
			columnVisibilityModel={columnVisibilityModel}
			disableColumnSelector
		/>
	);
}
