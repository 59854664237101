/*
  TODO: In order to better manage state, this couold be updated to use React Context
*/

import { setNodeCondition } from '@Actions';
import { DeleteIcon } from '@innovyze/stylovyze';
import { IconButton } from '@mui/material';
import { NodeConditionParams } from '@Types/rehab.types';
import React, { useEffect, useState } from 'react';
import {
	ArrowHeadType,
	getBezierPath,
	getEdgeCenter,
	getMarkerEnd,
	Position,
} from 'react-flow-renderer';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useStyles } from './ButtonEdge.styles';
import {
	selectGetRehabTreeStatus,
	selectGetRehabTreeValidationStatus,
	startNodeIdSelector,
} from '@Selectors/Rehab.selectors';
import { TREE_STATES } from '@Utils/TreeUtils';

const foreignObjectSize = 150;

interface Props {
	id: string;
	label: string;
	sourceX: number;
	sourceY: number;
	targetX: number;
	targetY: number;
	sourcePosition: Position;
	targetPosition: Position;
	arrowHeadType: ArrowHeadType;
	markerEndId: string;
}
export default function CustomEdge({
	id,
	label,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	arrowHeadType,
	markerEndId,
}: Props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [startNode, setStartNode] = useState(false);
	const currentTreeStartNodeId = startNodeIdSelector();

	const rehabTreeStatus = selectGetRehabTreeStatus();
	const rehabTreeValidationStatus = selectGetRehabTreeValidationStatus();
	const isDisabled =
		rehabTreeStatus === TREE_STATES.IN_PROGRESS ||
		rehabTreeValidationStatus === TREE_STATES.IN_PROGRESS;

	useEffect(() => {
		const startNodeId = id.split('_')[0] + '_' + id.split('_')[1];
		if (currentTreeStartNodeId === startNodeId) {
			setStartNode(true);
		}
	}, []);

	const onEdgeClick = (
		evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		id: any,
		condition: boolean,
	) => {
		evt.stopPropagation();
		const params: NodeConditionParams = {
			id: id,
			connectorCondition: condition,
		};
		dispatch(setNodeCondition(params));
	};

	const onEdgeDeleteClick = (
		evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		id: any,
	) => {
		evt.stopPropagation();
		const params: NodeConditionParams = {
			id: id,
			delete: true,
		};
		dispatch(setNodeCondition(params));
	};

	const edgePath = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});
	const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
	const [edgeCenterX, edgeCenterY] = getEdgeCenter({
		sourceX,
		sourceY,

		targetX,
		targetY,
	});

	return (
		<>
			<path
				id={id}
				className="react-flow__edge-path"
				d={edgePath}
				markerEnd={markerEnd}
			/>
			<foreignObject
				width={foreignObjectSize}
				height={foreignObjectSize}
				x={edgeCenterX - foreignObjectSize / 4}
				y={edgeCenterY - foreignObjectSize / 4}
				className={clsx('edgebutton-foreignobject', classes.edgeRoot)}
				requiredExtensions="http://www.w3.org/1999/xhtml">
				{!label ? (
					!startNode ? (
						<>
							<button
								className="edgebutton"
								onClick={event => onEdgeClick(event, id, true)}>
								{'True'}
							</button>
							|
							<button
								className="edgebutton"
								onClick={event =>
									onEdgeClick(event, id, false)
								}>
								{'False'}
							</button>
						</>
					) : !isDisabled ? (
						<IconButton
							size="small"
							className={clsx('edgebutton', classes.hoverIcon)}
							onClick={event => onEdgeDeleteClick(event, id)}>
							<DeleteIcon fontSize="small" />
						</IconButton>
					) : (
						[]
					)
				) : (
					<>
						{`${label} `}
						{!isDisabled ? (
							<IconButton
								size="small"
								className={clsx(
									'edgebutton',
									classes.hoverIcon,
								)}
								onClick={event => onEdgeDeleteClick(event, id)}>
								<DeleteIcon fontSize="small" />
							</IconButton>
						) : (
							[]
						)}
					</>
				)}
			</foreignObject>
		</>
	);
}

export function ReadOnlyCustomEdge({
	id,
	label,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	arrowHeadType,
	markerEndId,
}: Props) {
	const edgePath = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});
	const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
	const [edgeCenterX, edgeCenterY] = getEdgeCenter({
		sourceX,
		sourceY,

		targetX,
		targetY,
	});

	return (
		<>
			<path
				id={id}
				className="react-flow__edge-path"
				d={edgePath}
				markerEnd={markerEnd}
			/>
			<foreignObject
				width={foreignObjectSize}
				height={foreignObjectSize}
				x={edgeCenterX - foreignObjectSize / 4}
				y={edgeCenterY - foreignObjectSize / 4}
				requiredExtensions="http://www.w3.org/1999/xhtml">
				{`${label} `}
			</foreignObject>
		</>
	);
}
