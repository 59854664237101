import { getRehabResult, setTab } from '@Actions';
import { RehabResultDrawer } from '@Components/RehabResultDrawer';
import { InspectionStandard, RehabResults } from '@Types';
import { AssetType } from '@innovyze/lib-am-common';
import React from 'react';
import { useDispatch } from 'react-redux';
import RehabResultTable from './RehabResultTable';

export interface InspDetailActions {
	onInspectionDetailClicked?: (
		videoUrl: string,
		InspectionID: string,
		standard: InspectionStandard,
	) => void;
}

export interface AssetDetailActions {
	onAssetDetailClicked?: (assetId: string, assetType: string) => void;
}

export interface RehabResultProps {
	readonly viewerAccess: boolean;
	readonly treeId: string;
	readonly assetAction?: AssetDetailActions;
	readonly assetType: string;
	readonly loadResults: boolean;
	onResultsLoaded: (loadResults: boolean) => void;
}

export default function RehabResult({
	viewerAccess,
	treeId,
	assetAction,
	assetType,
	loadResults,
	onResultsLoaded,
}: RehabResultProps) {
	// hooks
	const dispatch = useDispatch();

	// states
	const [rehabResultToEdit, setRehabResultToEdit] = React.useState<
		undefined | null | RehabResults
	>(undefined);

	// effects
	React.useEffect(() => {
		if (loadResults) {
			dispatch(getRehabResult());
			onResultsLoaded(false);
		}
	}, [treeId, loadResults]);
	React.useEffect(() => {
		dispatch(setTab('Results'));
	}, []);

	return (
		<>
			<RehabResultTable
				assetType={assetType as AssetType}
				treeId={treeId}
				assetAction={assetAction}
				viewerAccess={viewerAccess}
				setRehabResultToEdit={setRehabResultToEdit}
			/>
			<RehabResultDrawer
				treeId={treeId}
				assetType={assetType}
				viewerAccess={viewerAccess}
				rehabResult={rehabResultToEdit}
			/>
		</>
	);
}
