import {
	FilterStoreState,
	Pagination,
	PaginationState,
	Row,
} from '@innovyze/lib-am-common';
import { ChoiceListPairs } from '@Utils/choiceListFilesUtils';
import { paginationInitialState } from '@innovyze/lib-am-common/Reducers';
import { Edge, Node as LeafNode } from 'react-flow-renderer';
import { CSSProperties } from 'styled-components';
import { RehabTreePublishStateType } from '@innovyze/lib-am-common';
import { GridFilterModel } from '@innovyze/stylovyze';

export interface Rules {
	id: any;
	recordType?: string;
	config?: string;
	field?: string;
	operator?: string;
	values?: [];
	value1?: [] | string | number;
	value2?: [] | string | number;
	unitLength?: [] | string | number;
	unit?: string;
	ruleCondition?: string;
	order?: number;
	result?: string;
	filters?: Rules[];
	options?: RuleOptions;
	groupedRules?: Rules[];
}
export interface GroupedRules {
	recordType?: string;
	field?: string;
	config?: string;
	operator?: string;
	value1?: any[];
	value2?: any[];
	unitLength?: any[];
	values?: any[];
	unit?: string;
	ruleCondition?: string;
	order?: number;
	result?: string;
	filters?: Rules[];
}

export interface Position {
	x: number;
	y: number;
}

export interface NodeDate {
	title?: string;
	filterCondition?: string;
	rules?: Rules[];
	actionDefectCodes?: string[];
	actionId?: string;
	treeId?: string;
	unitCost?: number;
	costUnit?: string;
	unit?: CostUnit;
	assetsCount?: AssetsCount;
}
export interface AssetsCount {
	currentNode?: number | null;
	truePath?: number | null;
	falsePath?: number | null;
	actionId?: string;
	treeId?: string;
	unitCost?: number;
	costUnit?: string;
	unit?: CostUnit;
}
export interface TreeNodeSchema {
	label?: string;
	position?: Position;
	type?: string;
	right?: TreeNodeSchema;
	left?: TreeNodeSchema;
	nodeData?: NodeDate;
	connectorCondition?: string;
	assetsCount?: AssetsCount;
}

export interface ReactFlowSchema {
	id: number | string | null;
	parentId?: string | number | null;
	type?: string;
	style?: CSSProperties;
	position?: Position;
	data?: {
		label: string;
		nodeData: NodeDate;
	};
	label?: string;
	source?: number | string | null;
	target?: number | string | null;
	connectorCondition?: string;
}

export interface NodeSchema {
	id: string | number | null;
	parentId?: string | number | null;
	left: NodeSchema | null;
	right: NodeSchema | null;
	position?: Position;
	title?: string;
	connectorCondition?: string;
	type?: string;
	filterCondition?: string;
	rules?: Rules[];
	style?: CSSProperties;
	actionDefectCodes?: string[];
	assetsCount?: AssetsCount;
	actionId?: string;
	treeId?: string;
	unitCost?: number;
	costUnit?: string;
	unit?: CostUnit;
}
export interface RehabDecisionTree {
	_id?: string;
	name: string;
	thumbnail: string;
	treeNodes: NodeSchema;
	draftNodes?: ReactFlowSchema[];
	status?: string;
	runBy?: string;
	lastRun?: string;
	systemType?: string;
	assetType?: string;
	inspectionStandard?: string;
	assetCount?: number;
	nodeCount?: number;
	validationData?: { errorMessage: string }[];
	configModelsValidationData?: { errorMessage: string }[];
}

export enum RecordType {
	PipeAssetAttributes = 'Attributes',
	Ready = 'ready',
	Submitted = 'submitted',
	InReview = 'inReview',
	Approved = 'approved',
	Rejected = 'rejected',
}

export interface TreeNode {
	_id: string;
	name: string;
	thumbnail?: string;
	treeNodes: LeafNode[];
	edges: Edge[];
}

export interface RehabTreeValidation {
	treeId: string;
	isValid: boolean;
	validationMessages: { errorMessage: string }[];
}

export interface ReactFlowDecisionTree {
	_id: string;
	name: string;
	systemType?: string;
	inspectionStandard: string;
	thumbnail: string;
	nodes: ReactFlowSchema[];
	status?: string;
	validationStatus?: string;
	runBy?: string;
	lastRun?: string;
	assetType?: string;
	assetCount?: number;
	nodeCount?: number;
}
export interface QueryOperator {
	type: string;
	items?: string[];
}

export interface RehabTreesResolvedResponse {
	data: RehabDecisionTree[];
	count: number;
}

export interface RehabTreeResolvedResponse {
	data: RehabDecisionTree;
}

export interface GetRehabTreeParams {
	Id: string;
}

export interface NodeConditionParams {
	id: string;
	delete?: boolean;
	connectorCondition?: boolean;
}

export interface NodePositionParams {
	Id: string;
	position: Position;
}

export interface NewDecisionTree {
	name: string;
	systemType: string;
	assetType: string;
	treeNodes: ReactFlowSchema[];
	inspectionStandard?: string;
}
export interface NewRehabTreeRequest {
	inReview?: {
		inspectionIds: string[];
	};
	approve?: {
		inspectionIds: string[];
	};
	reject?: {
		inspectionIds: string[];
	};
	submit?: {
		inspectionIds: string[];
	};
	missing_media?: {
		inspectionIds: string[];
	};
	ready?: {
		inspectionIds: string[];
	};
}

export enum RULE_CONDITION {
	AND = 'AND',
	OR = 'OR',
}

export interface RehabQueryChoiceList {
	[index: string]: any;
}

export interface RehabRuleChoiceListFiles {
	assetRecordType: RehabQueryChoiceList[];
	inspectionRecordType: RehabQueryChoiceList[];
	riskRecordType: RehabQueryChoiceList[];
	conditionRecordType: RehabQueryChoiceList[];
	defectFilterRecordType: RehabQueryChoiceList[];
	udtRecordType: RehabQueryChoiceList[];
	tasksRecordType: RehabQueryChoiceList[];
}
export interface ChoiceListStoreState {
	isLoading: boolean;
	RehabRuleChoiceList: RehabQueryChoiceList[]; //Also used for filter
	fieldOptions: [];
	allRehabRuleChoiceListFiles: RehabRuleChoiceListFiles;
}

export interface GetRehabActionParams {
	Id: string;
}

export interface GetRehabActionsParams {
	Id: string;
}

export interface RehabActionsResolvedResponse {
	data: RehabAction[];
	count: number;
}

export interface RehabActionResolvedResponse {
	data: RehabAction;
}
export interface AddTreePayload {
	treeId: string;
}
export interface AddRehabTreePayload {
	data: AddTreePayload;
}
export interface ValidateRehabTreeParams {
	treeId: string;
}
export interface RunRehabTreeParams {
	treeId: string;
}
export interface RehabTreeId {
	treeId: string;
	onComplete: (treeId: string) => void;
}
// export interface AddRehabActionParams {
// 	actionId: string;
// 	treeId: string;
// 	unitCost: number;
// 	costUnit: string; //"USD" or "GBP", now is not used
// 	unit: CostUnit;
// 	settings: {
// 		enabled: boolean;
// 		defects: string[];
// 	};
// 	notes: string;
// }
export interface ActionObj {
	nodeId: string;
	actionId: string | null;
	actionName: string;
	parentId?: string;
	connectorCondition?: string;
	unitCost: number;
	costUnit: string;
	unit: string;
}
export enum NODE_TYPES {
	action = 'actionInput',
	query = 'innoInput',
}

export enum CostUnit {
	each = 'each',
	length = 'length',
}

export interface RehabAction {
	_id: string;
	actionId: string;
	treeId: string;
	unitCost: number;
	costUnit: string; //"USD" or "GBP", now is not used
	unit: CostUnit;
	settings: {
		enabled: boolean;
		defects: string[];
	};
	notes: string;
}

export interface RehabResults {
	treeId: string;
	assetId: string;
	rehabAction?: RehabAction;
	totalCost?: number;
	costCalculation?: string;
	locked?: string;
	modifiedBy?: string;
	draftRehabAction?: string;
	actionNodeId?: string;
	notes?: string;
}

export interface RehabCost {
	ASSET_ID: string;
	COST_CALCULATION_FT?: string;
	COST_CALCULATION_METER?: string;
	DEFECT_CODES?: string;
	DRAFT_RRHAB_ACTION?: string;
	FINAL_RRHAB_ACTION?: string;
	TOTAL_COST?: number;
}

export interface RehabCostParams {
	treeId: string;
	actionId: string;
	assetId: string;
	assetType: string;
}

export interface RehabCostResolvedResponse {
	data: RehabCost;
}

export interface ConfigRecordTypeFieldData {
	label: string;
	data: ChoiceListPairs[];
}

export interface RiskConfig {
	configId: string;
	name: string;
}

export interface TaskConfig {
	configId: string;
	name: string;
}

export interface RehabTreeList {
	_id: string;
	name: string;
}

export interface RehabTreesAndIdsResponse {
	pagination: Pagination;
	data: Row[] | unknown[];
	allTreeIds: RehabTreeList[];
}

export interface RehabCard {
	_id: string;
	name: string;
	status?: string;
	runBy?: string;
	lastRun?: string;
	assetType?: string;
	systemType?: string;
	assetCount?: number;
	nodeCount?: number;
	publishState?: RehabTreePublishStateType;
}

export interface RehabTreeCardPayload {
	pagination: Pagination;
	data: RehabCard[];
}

export interface RehabTreeStatusResolvedResponse {
	status: string;
}

export const rehabTreesPaginationDefault = (): Pagination => ({
	...paginationInitialState,
	search: undefined,
	sortKey: 'updatedAt',
	sortDescending: true,
});

export interface RehabStoreState {
	isLoading: boolean;
	isCreating: boolean;
	isDeleting: boolean;
	isRenaming: boolean;
	rehabTreeNameError: string;
	refreshList: boolean;
	isCostLoading: boolean;
	rehabDetails: TreeNode[];
	newTree: TreeNode;
	tab: string;
	saveRun: boolean;
	// isNewTree: boolean;
	reactFlowDecisionTrees: ReactFlowDecisionTree[]; // TODO: REPLACE THIS !!!
	newDecisionTree: NewDecisionTree;
	currentTreeId: string;
	fullRehabTree: ReactFlowDecisionTree;
	connectorObject: NodeConditionParams;
	rehabActions: RehabAction[];
	rehabResult: PaginationState;
	onlyShowLocked: boolean;
	dropdownFilters: FilterStoreState;
	rehabResultFilter?: GridFilterModel;
	search: '';
	rehabTreeValidation: RehabTreeValidation;
	startNodeId: string;
	rehabCost: RehabCost;
	rehabTrees: PaginationState;
	rehabTreesList: RehabTreeList[];
	isRehabTreesListLoading: boolean;
	allRiskConfigModels: RiskConfig[];
	rehabActionToAdd?: RehabAction;
}

export interface RuleOptions {
	valueType: string;
	type: string;
}
