import './QueryDrawer.component.styles.css';
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Button, Grid, TextField, Typography } from '@mui/material';
import {
	Drawer,
	closeDrawer,
	LoadingPage,
	useIsFeatureEnabled,
} from '@innovyze/stylovyze';
import {
	Rules,
	ReactFlowSchema,
	NodeDate,
	RehabRuleChoiceListFiles,
	ReactFlowDecisionTree,
} from '@Types';
import { RulesList } from '@Components';
import { useDispatch } from 'react-redux';
import RuleConfiguration from '@Components/RuleConfiguration';
import { Actions, PlaceholderGrid } from './QueryDrawer.component.style';
import CanvasIcon from '@Components/Tools/CanvasIcon.svg';
import { FIELDS_TYPES, RECORD_TYPE } from '@Utils/choiceListMapping';
import {
	getValueTypeForSpecificOperator,
	initMultiSelectFieldForEdit,
} from '@Utils/choiceListFilesUtils';
import { format } from 'date-fns';
import {
	selectAllRehabRuleChoiceListFiles,
	selectChoiceListIsLoading,
} from '@Selectors/ChoiceList.selectors';
import { getAllRehabRuleChoiceListFiles } from '@Actions/ChoiceList.action';
import { useGlobalization } from '@Translations';
export const getTimeStampFromDateTime = (date: string) => {
	//adding .replace(/-/g, "/") to solve the issue in safari
	return new Date(date.replace(/-/g, '/'));
};
import { DateTime } from 'luxon';

const getInitialValuesForEdit = (
	rule: Rules,
	recordTypeChoiceListFiles: RehabRuleChoiceListFiles,
): any => {
	// Fix up DOSE_NOT_EQUALS
	let operator = rule.operator;
	if (operator) {
		operator = operator.replace('DOSE_NOT_EQUALS', 'DOES_NOT_EQUAL');
	}

	let valueType = '';
	if (rule.recordType && rule.field && operator) {
		valueType = getValueTypeForSpecificOperator(
			recordTypeChoiceListFiles,
			rule.recordType,
			rule.field,
			operator,
			rule.config,
		);
	}
	const config = 'config' in rule ? rule.config : '';
	let luxonDt;
	let luxonDt2;
	switch (valueType) {
		case FIELDS_TYPES.BOOLEAN:
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1: rule.value1,
				value2: rule.value2,
				unitLength: rule.unitLength,
				values: rule.values,
				ruleCondition: rule.ruleCondition,
				unit: rule.unit,
				filters: rule.filters,
			};
		case FIELDS_TYPES.TEXT_ONE_FIELD:
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1: rule.value1,
				value2: rule.value2,
				unitLength: rule.unitLength,
				values: rule.values,
				ruleCondition: rule.ruleCondition,
				unit: rule.unit,
				filters: rule.filters,
			};
		case FIELDS_TYPES.PERCENTAGE_TEXT:
		case FIELDS_TYPES.NUMERICAL_ONE_FIELD:
		case FIELDS_TYPES.NUMERICAL_UNRESTRICTED_ONE_FIELD:
		case FIELDS_TYPES.CLOCK_ONE_FIELD:
		case FIELDS_TYPES.CLOCK_EXTENT_ONE_FIELD:
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1: rule.value1,
				value2: rule.value2,
				unitLength: rule.unitLength,
				values: rule.values,
				ruleCondition: rule.ruleCondition,
				unit: rule.unit,
				filters: rule.filters,
			};
		case FIELDS_TYPES.PERCENTAGE_TWO_FIELDS:
		case FIELDS_TYPES.NUMERICAL_TWO_FIELDS:
		case FIELDS_TYPES.NUMERICAL_UNRESTRICTED_TWO_FIELDS:
		case FIELDS_TYPES.CLOCK_TWO_FIELDS:
		case FIELDS_TYPES.CLOCK_EXTENT_TWO_FIELDS:
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1: rule.value1,
				value2: rule.value2,
				unitLength: rule.unitLength,
				values: rule.values,
				ruleCondition: rule.ruleCondition,
				unit: rule.unit,
				filters: rule.filters,
			};
		case FIELDS_TYPES.MULTI_SELECT_ONE_FIELD: {
			if (rule.recordType == RECORD_TYPE.INSPECTION) {
				const choiceListValues = initMultiSelectFieldForEdit(
					recordTypeChoiceListFiles,
					rule,
				);
				return {
					id: rule.id,
					recordType: rule.recordType,
					config: config,
					field: rule.field,
					operator: operator,
					value1:
						rule.field?.toLowerCase() ==
							'PACP_CODE'.toLowerCase() ||
						rule.field?.toLowerCase() == 'WSA_CODE'.toLowerCase()
							? rule.value1
							: rule.value1 && choiceListValues,
					value2: rule.value2,
					unitLength: rule.unitLength,
					values: rule.values,
					ruleCondition: rule.ruleCondition,
					unit: rule.unit,
					filters: rule.filters,
				};
			} else {
				return {
					id: rule.id,
					recordType: rule.recordType,
					config: config,
					field: rule.field,
					operator: operator,
					value1:
						rule.field?.toLowerCase() ==
							'PACP_CODE'.toLowerCase() ||
						rule.field?.toLowerCase() == 'WSA_CODE'.toLowerCase()
							? rule.value1
							: rule.value1 &&
							  (rule.value1 as Array<string>).map(
									(value: string) => {
										return {
											label: value,
											value: value,
										};
									},
							  ),
					value2: rule.value2,
					unitLength: rule.unitLength,
					values: rule.values,
					ruleCondition: rule.ruleCondition,
					unit: rule.unit,
					filters: rule.filters,
				};
			}
		}
		case FIELDS_TYPES.DATE_ONE_FIELD:
			//adjusting UTC datetime to local time while keeping the same
			//date. This is needed because date picker can only work with
			//local time.
			luxonDt = DateTime.fromISO((rule.value1 as Array<any>)[0]);
			luxonDt = luxonDt.minus({ minutes: luxonDt.offset });
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1:
					rule.value1 && (rule.value1 as Array<string>).length > 0
						? getTimeStampFromDateTime(
								format(
									luxonDt.toJSDate(),
									'yyyy-MM-dd HH:mm:ss',
								),
						  )
						: rule.value1,
				value2: rule.value2,
				unitLength: rule.unitLength,
				ruleCondition: rule.ruleCondition,
				//values: rule.values,
				unit: rule.unit,
				filters: rule.filters,
			};
		case FIELDS_TYPES.DATE_TWO_FIELDS:
			luxonDt = DateTime.fromISO((rule.value1 as Array<any>)[0]);
			luxonDt = luxonDt.minus({ minutes: luxonDt.offset });
			luxonDt2 = DateTime.fromISO(rule.value2 as string);
			luxonDt2 = luxonDt2.minus({ minutes: luxonDt2.offset });
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1:
					rule.value1 && (rule.value1 as Array<string>).length > 0
						? getTimeStampFromDateTime(
								format(
									luxonDt.toJSDate(),
									'yyyy-MM-dd HH:mm:ss',
								),
						  )
						: rule.value1,
				unitLength: rule.unitLength,
				value2: rule.value2
					? getTimeStampFromDateTime(
							format(luxonDt2.toJSDate(), 'yyyy-MM-dd HH:mm:ss'),
					  )
					: rule.value2,
				values: rule.values,
				ruleCondition: rule.ruleCondition,
				unit: rule.unit,
				filters: rule.filters,
			};
		case FIELDS_TYPES.DAYS_SINCE:
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1: rule.value1,
				value2: rule.value2,
				values: rule.values,
				ruleCondition: rule.ruleCondition,
				unit: rule.unit,
				filters: rule.filters,
			};
		case FIELDS_TYPES.YEARS_SINCE:
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1: rule.value1,
				value2: rule.value2,
				unitLength: rule.unitLength,
				values: rule.values,
				ruleCondition: rule.ruleCondition,
				unit: rule.unit,
				filters: rule.filters,
			};
		default:
			return {
				id: rule.id,
				recordType: rule.recordType,
				config: config,
				field: rule.field,
				operator: operator,
				value1: rule.value1,
				value2: rule.value2,
				unitLength: rule.unitLength,
				values: rule.values,
				ruleCondition: rule.ruleCondition,
				unit: rule.unit,
				filters: rule.filters,
			};
	}
};

export interface QueryFormValues {
	name: string;
	rules: Rules[];
	type: string;
}

interface QueryDrawerProps {
	isNew: boolean;
	queryValues: QueryFormValues;
	allNodes: any[];
	onAddToTree: (reactFlowSchema: ReactFlowSchema) => void;
	onDrawerClose: () => void;
	currentTree: ReactFlowDecisionTree;
}
export const QueryDrawer = ({
	isNew,
	queryValues,
	onAddToTree,
	onDrawerClose,
	currentTree,
}: QueryDrawerProps) => {
	const shouldShowFullDefects = !!useIsFeatureEnabled(
		'info-360-rehab-observations',
	);

	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const [drawerWidth, setDrawerWidth] = useState<number>(550);
	const [addRule, setAddRule] = useState<boolean>(false);
	const [queryName, setQueryName] = useState('');
	const [canAddToTree, setCanAddToTree] = useState(false);
	const [allRules, setAllRules] = useState<Rules[]>([]);
	const [tempRule, setTempRule] = useState<Rules>();
	const [ruleIdToEdit, setRuleIdToEdit] = useState<undefined | string>(
		undefined,
	);
	const [groupedRuleIdToEdit, setGroupedRuleIdToEdit] = useState<
		null | string
	>(null);
	const [ruleCondition, setRuleCondition] = useState('');
	const [groupedRuleId, setGroupedRuleId] = useState('');

	const [isFilter, setIsFilter] = useState<boolean>(false);
	const [addFilter, setAddFilter] = useState<boolean>(false);
	const [canAddToQuery, setCanAddToQuery] = useState(false);
	const [allFilters, setAllFilters] = useState<Rules[]>([]);
	const [filterIdToEdit, setFilterIdToEdit] = useState<undefined | string>(
		undefined,
	);
	const [groupedFilterIdToEdit, setGroupedFilterIdToEdit] = useState<
		null | string
	>(null);
	const [filterCondition, setFilterCondition] = useState('');
	const [groupedFilterId, setGroupedFilterId] = useState('');

	const [queryNameError, setQueryNameError] = useState(false);
	const [queryNameErrorText, setQueryNameErrorText] = useState('');
	const [isQueryPanelDisabled, setIsQueryPanelDisabled] = useState(false);
	const [isFilterPanelDisabled, setIsFilterPanelDisabled] = useState(false);
	const useStyles = makeStyles(() =>
		createStyles({
			paper: {
				top: 50,
				width: drawerWidth,
				overflow: 'hidden',
				overflowY: 'auto',
				height: '-webkit-fill-available',
			},
		}),
	);
	const choiceListIsLoading = selectChoiceListIsLoading();
	const recordTypeChoiceListFiles = selectAllRehabRuleChoiceListFiles();
	const classes = useStyles();
	let initialValues: QueryFormValues = {
		name: queryValues.name,
		rules: queryValues.rules,
		type: queryValues.type,
	};

	useEffect(() => {
		const { systemType, assetType, inspectionStandard } = currentTree;
		if (!choiceListIsLoading) {
			dispatch(
				getAllRehabRuleChoiceListFiles({
					systemType,
					assetType,
					inspectionStandard,
					t,
					shouldShowFullDefects,
				}),
			);
		}
	}, []);

	useEffect(() => {
		if (isNew) {
			setTempRule(undefined);
			setAllRules([]);
			setAllFilters([]);
		}
	}, [isNew]);

	useEffect(() => {
		setAllRules(queryValues.rules);
	}, [queryValues.rules]);

	useEffect(() => {
		setQueryName(queryValues.name);
	}, [queryValues.name]);

	const closeQueryDrawer = () => {
		if (isFilter) {
			setAddFilter(false);
			setDrawerWidth(1000);
			initialValues = {
				name: '',
				rules: [],
				type: '',
			};
			setAllFilters([]);
			setIsFilter(false);
		} else {
			setAddRule(false);
			setDrawerWidth(550);
			initialValues = {
				name: '',
				rules: [],
				type: '',
			};
			setAllRules([]);
			setCanAddToTree(false);
			onDrawerClose();
		}
	};
	const handleQueryNameChangeValue = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		if (event.target && event.target.value !== '') {
			setQueryName(event.target.value);
			if (allRules.length > 0) setCanAddToTree(true);
			else {
				setCanAddToTree(false);
			}

			setQueryNameError(false);
			setQueryNameErrorText('');

			const rexp2 = new RegExp('^.{1,200}$');
			const test2 = rexp2.test(event.target.value);
			if (!test2) {
				setQueryNameError(true);
				setQueryNameErrorText(
					t('Name cannot contain more than 200 characters.'),
				);
				setCanAddToTree(false);
			}
		} else {
			setCanAddToTree(false);
		}
	};
	const saveQueryToTree = () => {
		setQueryNameError(false);
		setQueryNameErrorText('');

		const nodeData: NodeDate = {
			rules: allRules,
		};
		const newInput: ReactFlowSchema = {
			id: '',
			data: {
				label: queryName,
				nodeData: nodeData,
			},
			label: queryName,
		};
		setCanAddToTree(false);
		onAddToTree(newInput);
		dispatch(closeDrawer());
	};

	const saveFilterToRule = () => {
		setIsFilter(false);
		setDrawerWidth(1000);
	};

	const addRuleToQuery = (newRule: Rules) => {
		if (addFilter) {
			setFilterIdToEdit('');
			setGroupedFilterIdToEdit('');
			let updatedFilters: Rules[] = [];
			if (filterIdToEdit) {
				if (!groupedFilterIdToEdit) {
					updatedFilters = allFilters.map(filter => {
						const updatedFilter: Rules =
							filter.id === filterIdToEdit
								? {
										...filter,
										...newRule,
								  }
								: filter;
						return updatedFilter;
					});
				} else {
					const newFilters = [...allFilters];
					const filterIndex = newFilters.findIndex(
						el => el.id === filterIdToEdit,
					);

					const updatedGroupedFilters = newFilters[
						filterIndex
					].groupedRules?.map(groupedFilter => {
						if (groupedFilter.id === groupedFilterIdToEdit) {
							return newRule;
						}
						return groupedFilter;
					});
					newFilters[filterIndex].groupedRules =
						updatedGroupedFilters;
					updatedFilters = newFilters;
				}
			} else {
				if (groupedFilterId) {
					updatedFilters = allFilters?.map(filter => {
						const updatedFilter: Rules =
							filter.id === groupedFilterId
								? {
										...filter,
										groupedRules: filter.groupedRules
											? [...filter.groupedRules, newRule]
											: [newRule],
								  }
								: filter;
						return updatedFilter;
					});
				} else {
					updatedFilters = [...allFilters, newRule];
				}
			}
			setAllFilters(updatedFilters);
			setAddFilter(false);
			setCanAddToQuery(true);
			setDrawerWidth(550);
			setGroupedFilterId('');
			setFilterCondition('');
		} else {
			newRule.filters = allFilters ? [...allFilters] : [];
			setRuleIdToEdit('');
			setGroupedRuleIdToEdit('');
			let updatedRules: Rules[] = [];
			if (ruleIdToEdit) {
				if (!groupedRuleIdToEdit) {
					updatedRules = allRules.map(rule => {
						const updatedRule: Rules =
							rule.id === ruleIdToEdit
								? {
										...rule,
										...newRule,
								  }
								: rule;
						return updatedRule;
					});
				} else {
					const newRules = [...allRules];
					const ruleIndex = newRules.findIndex(
						el => el.id === ruleIdToEdit,
					);

					const updatedGroupedRules = newRules[
						ruleIndex
					].groupedRules?.map(groupedRule => {
						if (groupedRule.id === groupedRuleIdToEdit) {
							return newRule;
						}
						return groupedRule;
					});
					newRules[ruleIndex] = {
						...newRules[ruleIndex],
						groupedRules: updatedGroupedRules,
					};
					updatedRules = newRules;
				}
			} else {
				if (groupedRuleId) {
					updatedRules = allRules?.map(rule => {
						const updatedRule: Rules =
							rule.id === groupedRuleId
								? {
										...rule,
										groupedRules: rule.groupedRules
											? [...rule.groupedRules, newRule]
											: [newRule],
								  }
								: rule;
						return updatedRule;
					});
				} else {
					updatedRules = [...allRules, newRule];
				}
			}
			setAllRules(updatedRules);
			setAddRule(false);
			if (queryName != '' && !queryNameError) {
				setCanAddToTree(true);
			}
			setDrawerWidth(550);
			setGroupedRuleId('');
			setRuleCondition('');
		}
	};
	const addCancelRule = () => {
		if (isFilter) {
			setAddFilter(false);
		} else {
			setAddRule(false);
		}
		setDrawerWidth(550);
	};
	const newRuleHandler = () => {
		if (isFilter) {
			setFilterIdToEdit('');
			setGroupedFilterIdToEdit('');
			setAddFilter(true);
		} else {
			setRuleIdToEdit('');
			setGroupedRuleIdToEdit('');
			setAddRule(true);
		}
		setDrawerWidth(1000);
	};
	const setRulesHander = (rules: Rules[]) => {
		if (isFilter) {
			setCanAddToQuery(true);
			setAllFilters(rules);
		} else {
			if (queryName != '' && !queryNameError) {
				setCanAddToTree(true);
			}
			setAllRules(rules);
		}
	};
	const editRuleHandler = (ruleId: string, groupedId: string | null) => {
		if (isFilter) {
			setFilterIdToEdit(ruleId);
			setGroupedFilterIdToEdit(groupedId);
			setAddFilter(true);
		} else {
			setRuleIdToEdit(ruleId);
			setGroupedRuleIdToEdit(groupedId);
			setAddRule(true);
		}
		setDrawerWidth(1000);
	};
	const deleteRuleHandler = (id: string, groupedId: string | null) => {
		if (isFilter) {
			setFilterIdToEdit('');
			setGroupedFilterIdToEdit('');
			setTempRule(undefined);
			setCanAddToQuery(true);
			const newFilters = [...allFilters];
			let newFiltersUpdated: Rules[] = [];
			if (!groupedId) {
				newFiltersUpdated = newFilters.map((filter, index) => {
					if (filter.id === id && index === 0 && newFilters[1]) {
						newFilters[1] = {
							...newFilters[1],
							ruleCondition: '',
						};
					}
					return filter;
				});
				newFiltersUpdated = newFiltersUpdated.filter(
					filter => filter.id !== id,
				);
				setAllFilters(newFiltersUpdated);
			} else {
				try {
					newFiltersUpdated = newFilters.map(filter => {
						if (filter.id === id) {
							const newFilterGroupedFilters =
								filter.groupedRules?.filter(
									ele => ele.id !== groupedId,
								);
							return {
								...filter,
								groupedRules: newFilterGroupedFilters,
							};
						}
						return filter;
					});
					setAllFilters(newFiltersUpdated);
				} catch (e) {
					console.log(e);
				}
			}
			//also need to update filters in current rule being edited
			const newRulesUpdated = allRules.map(rule => {
				const newRule = { ...rule };
				if (newRule.id === ruleIdToEdit) {
					newRule.filters =
						newFiltersUpdated == null ? [] : [...newFiltersUpdated];
				}
				return newRule;
			});
			setAllRules(newRulesUpdated);
		} else {
			setRuleIdToEdit('');
			setGroupedRuleIdToEdit('');
			setCanAddToTree(true);
			const newRules = [...allRules];

			if (!groupedId) {
				const newRulesUpdated: Rules[] = newRules.map((rule, index) => {
					if (rule.id === id && index === 0 && newRules[1]) {
						newRules[1] = { ...newRules[1], ruleCondition: '' };
					}
					return rule;
				});
				setAllRules(newRulesUpdated.filter(rule => rule.id !== id));
			} else {
				try {
					const newRulesUpdated: Rules[] = newRules.map(rule => {
						if (rule.id === id) {
							const newRuleGroupedRules =
								rule.groupedRules?.filter(
									ele => ele.id !== groupedId,
								);
							return {
								...rule,
								groupedRules: newRuleGroupedRules,
							};
						}
						return rule;
					});
					setAllRules(newRulesUpdated);
				} catch (e) {
					console.log(e);
				}
			}
		}
	};

	const AddRuleCondition = (condition: string, groupedRuleId: string) => {
		if (isFilter) {
			setFilterCondition(condition);
			setGroupedFilterId(groupedRuleId);
		} else {
			setRuleCondition(condition);
			setGroupedRuleId(groupedRuleId);
		}
	};

	const getRuleFormValues = (): Rules => {
		const defaultFormValues: Rules = {
			id: '',
			recordType: '',
			config: '',
			field: '',
			operator: '',
			value1: '',
			value2: '',
			unitLength: '',
			values: [],
			unit: '',
		};

		if (isFilter) {
			let filterToEdit: Rules | undefined = undefined;

			if (!groupedFilterIdToEdit) {
				filterToEdit = allFilters.find(el => el.id === filterIdToEdit);
			} else {
				allFilters.map(filter => {
					if (filter.id === filterIdToEdit) {
						filterToEdit = filter.groupedRules?.find(
							el => el.id === groupedFilterIdToEdit,
						);
					}
				});
			}

			if (!filterToEdit) {
				return defaultFormValues;
			}
			return getInitialValuesForEdit(
				filterToEdit,
				recordTypeChoiceListFiles,
			);
		} else {
			let ruleToEdit: Rules | undefined = undefined;

			if (!groupedRuleIdToEdit) {
				ruleToEdit = allRules.find(el => el.id === ruleIdToEdit);
			} else {
				allRules.map(rule => {
					if (rule.id === ruleIdToEdit) {
						ruleToEdit = rule.groupedRules?.find(
							el => el.id === groupedRuleIdToEdit,
						);
					}
				});
			}

			if (!ruleToEdit) {
				if (tempRule) return tempRule;
				return defaultFormValues;
			}
			return getInitialValuesForEdit(
				ruleToEdit,
				recordTypeChoiceListFiles,
			);
		}
	};

	const openFilterHandler = () => {
		setIsFilter(true);
		setDrawerWidth(550);
	};

	const PlaceholderForEmptyCanvas = (
		<PlaceholderGrid
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			hidden={isFilter ? allFilters?.length > 0 : allRules?.length > 0}>
			<div>
				<img src={CanvasIcon} alt={t('Decision Tree Icon')} />
			</div>
			<div>
				<Typography align="center" gutterBottom variant="h6">
					{t(
						isFilter
							? 'No filters have been added'
							: 'Your query is blank',
					)}
				</Typography>
			</div>
			<div>
				<Typography align="center" gutterBottom variant="body1">
					{t(
						isFilter
							? 'Add your first filter to get started'
							: 'Add your first expression to get started',
					)}
				</Typography>
			</div>
			<div>
				<Button
					variant="contained"
					color="primary"
					onClick={newRuleHandler}
					disabled={isFilter ? addFilter : addRule}>
					{t(isFilter ? 'Add Filter' : 'Add Rule')}
				</Button>
			</div>
		</PlaceholderGrid>
	);
	const updateQueryPanelDisabledState = (state: boolean) => {
		setIsQueryPanelDisabled(state);
	};
	const updateFilterPanelDisabledState = (state: boolean) => {
		setIsFilterPanelDisabled(state);
	};
	const btnLabel = isNew ? t('Add to Tree') : t('Save');
	const ruleListPanel =
		allRules?.length == 0 ? (
			<Grid item xs={addRule ? 6 : 12}>
				<Grid container direction="column">
					{PlaceholderForEmptyCanvas}
				</Grid>
			</Grid>
		) : (
			<Grid
				item
				xs={addRule ? 6 : 12}
				style={{
					maxHeight: 'calc( 100vh - 150px )',
					padding: '26px 0 0 5px',
				}}>
				<Grid container direction="column">
					<RulesList
						setQueryPanelDisabledState={
							updateQueryPanelDisabledState
						}
						isQueryPanelDisabled={isQueryPanelDisabled}
						onAddRuleCondition={AddRuleCondition}
						setRules={setRulesHander}
						rules={allRules}
						onAddRule={newRuleHandler}
						onEditRule={editRuleHandler}
						onDeleteRule={deleteRuleHandler}
						isFilter={isFilter}></RulesList>
				</Grid>
			</Grid>
		);
	const filterListPanel =
		allFilters?.length == 0 ? (
			<Grid item xs={addFilter ? 6 : 12}>
				<Grid container direction="column">
					{PlaceholderForEmptyCanvas}
				</Grid>
			</Grid>
		) : (
			<Grid
				item
				xs={addFilter ? 6 : 12}
				style={{
					maxHeight: 'calc( 100vh - 150px )',
					padding: '26px 0 0 5px',
				}}>
				<Grid container direction="column">
					<RulesList
						setQueryPanelDisabledState={
							updateFilterPanelDisabledState
						}
						isQueryPanelDisabled={isFilterPanelDisabled}
						onAddRuleCondition={AddRuleCondition}
						setRules={setRulesHander}
						rules={allFilters}
						onAddRule={newRuleHandler}
						onEditRule={editRuleHandler}
						onDeleteRule={deleteRuleHandler}
						isFilter={isFilter}></RulesList>
				</Grid>
			</Grid>
		);
	const listPanel = isFilter ? filterListPanel : ruleListPanel;
	const ruleQueryPanel = addRule ? (
		<RuleConfiguration
			setQueryPanelDisabledState={updateQueryPanelDisabledState}
			groupedRuleId={groupedRuleId}
			ruleCondition={ruleCondition}
			onAddRule={addRuleToQuery}
			onCancelRule={addCancelRule}
			initialRuleValues={getRuleFormValues()}
			openFilterHandler={openFilterHandler}
			isFilter={isFilter}
			setTempRule={setTempRule}
			allFilters={allFilters}
			setAllFilters={setAllFilters}
			systemType={currentTree.systemType || 'Sanitary Sewer'}
			assetType={currentTree.assetType || 'pipe'}
			inspectionStandard={
				currentTree.inspectionStandard || 'PACP'
			}></RuleConfiguration>
	) : null;
	const filterQueryPanel = addFilter ? (
		<RuleConfiguration
			setQueryPanelDisabledState={updateFilterPanelDisabledState}
			groupedRuleId={groupedFilterId}
			ruleCondition={filterCondition}
			onAddRule={addRuleToQuery}
			onCancelRule={addCancelRule}
			initialRuleValues={getRuleFormValues()}
			openFilterHandler={openFilterHandler}
			isFilter={isFilter}
			setTempRule={setTempRule}
			allFilters={allFilters}
			setAllFilters={setAllFilters}
			systemType={currentTree.systemType || 'Sanitary Sewer'}
			assetType={currentTree.assetType || 'pipe'}
			inspectionStandard={
				currentTree.inspectionStandard || 'PACP'
			}></RuleConfiguration>
	) : null;
	const queryPanel = isFilter ? filterQueryPanel : ruleQueryPanel;
	return (
		<Drawer
			drawerId="addQueryDrawer"
			onClose={closeQueryDrawer}
			closeOnClickAway={false}
			classes={{ paper: classes.paper }}>
			<Grid
				sx={{ display: !isFilter ? 'none' : 'flex' }}
				className="rule-details-header"
				container
				direction="row"
				alignItems="center">
				<Grid item xs={12} className="header-btns">
					<Actions>
						<Button
							variant="contained"
							color={
								!canAddToQuery || isFilterPanelDisabled
									? 'primary'
									: 'secondary'
							}
							data-cy="Cancel"
							onClick={closeQueryDrawer}
							disabled={isFilterPanelDisabled}>
							{t('Cancel')}
						</Button>
						<Button
							variant="contained"
							data-cy="AddRule"
							color="primary"
							onClick={saveFilterToRule}
							disabled={!canAddToQuery || isFilterPanelDisabled}>
							{t('Save')}
						</Button>
					</Actions>
				</Grid>
			</Grid>
			<Grid
				sx={{ display: isFilter ? 'none' : 'flex' }}
				className="rule-details-header"
				container
				direction="row"
				alignItems="center">
				<Grid item xs={7}>
					<TextField
						label={t('Query Name')}
						defaultValue={initialValues.name}
						multiline
						maxRows={2}
						error={queryNameError}
						className="fullText"
						onChange={handleQueryNameChangeValue}
						helperText={queryNameError ? queryNameErrorText : ''}
					/>
				</Grid>
				<Grid item xs={5} className="header-btns">
					<Actions>
						<Button
							variant="contained"
							data-cy="Cancel"
							color={
								!canAddToTree || isQueryPanelDisabled
									? 'primary'
									: 'secondary'
							}
							onClick={closeQueryDrawer}
							disabled={isQueryPanelDisabled}>
							{t('Cancel')}
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={saveQueryToTree}
							disabled={!canAddToTree || isQueryPanelDisabled}>
							{btnLabel}
						</Button>
					</Actions>
				</Grid>
			</Grid>
			{choiceListIsLoading ? (
				<div style={{ height: 'calc( 100vh - 250px )' }}>
					<LoadingPage title={t('Loading field options..')} />
				</div>
			) : (
				<div
					style={{
						height: 'calc( 100vh - 230px )',
					}}>
					<Grid
						className="query-container__builder"
						container
						direction="row">
						{queryPanel}
						{listPanel}
					</Grid>
				</div>
			)}
		</Drawer>
	);
};

export default QueryDrawer;
