import { addNamespace } from '@Utils/actions';
import { createAction } from '@reduxjs/toolkit';
import {
	ColumnFilterOptions,
	ColumnFilterValue,
	Pagination,
	ResolvedResponse,
	Sort2 as Sort,
	Sort2,
	Status,
	Translations,
} from '@innovyze/lib-am-common';
import { Edge as LeafConnection, Node as LeafNode } from 'react-flow-renderer';
import {
	TreeNode,
	ReactFlowDecisionTree,
	GetRehabTreeParams,
	ReactFlowSchema,
	NodeConditionParams,
	NodePositionParams,
	GetRehabActionsParams,
	RehabAction,
	ValidateRehabTreeParams,
	RunRehabTreeParams,
	RehabTreeValidation,
	RehabResults,
	RehabTreeId,
	RehabCostParams,
	RehabCost,
	rehabTreesPaginationDefault,
	RehabTreeList,
	RiskConfig,
	NewDecisionTree,
} from '@Types';
import { GridFilterModel } from '@innovyze/stylovyze';
export const getRehabDetails = createAction('rehab/getRehabDetails');

export const getRiskDetailsDefaulted = () => getRehabDetails();

export const reloadRehabDetails = createAction('rehab/reloadRehabDetails');

export const createRehabTree = createAction<TreeNode>('rehab/createRehabTree');
export const createNewRehabTree = createAction<string>(
	'rehab/createNewRehabTree',
);
export const addNewNode = createAction<LeafNode>('rehab/addNewNode');
export const addNewEdge = createAction<LeafConnection>('rehab/addNewEdge');

export const setTab = createAction<string>('rehab/setTab');
export const setCurrentTreeId = createAction<string>('rehab/setCurrentTreeId');
export const setNewTree = createAction('rehab/setNewTree');

export const getRehabTree =
	createAction<GetRehabTreeParams>('rehab/getRehabTree');
export const getRehabTreeRejected = createAction('rehab/getRehabTreeRejected');
export const getRehabTreeResolved = createAction<ReactFlowDecisionTree>(
	'rehab/getRehabTree/resolved',
);

export const getRehabTreeStatus = createAction<GetRehabTreeParams>(
	'rehab/getRehabTreeStatus',
);
export const getRehabTreeStatusRejected = createAction(
	'rehab/getRehabTreeStatusRejected',
);
export const getRehabTreeStatusResolved = createAction<string>(
	'rehab/getRehabTreeStatus/resolved',
);

export const getRehabTreeValidationStatus = createAction<GetRehabTreeParams>(
	'rehab/getRehabTreeValidationStatus',
);
export const getRehabTreeValidationStatusRejected = createAction(
	'rehab/getRehabTreeValidationStatusRejected',
);
export const getRehabTreeValidationStatusResolved = createAction<string>(
	'rehab/getRehabTreeValidationStatus/resolved',
);

export const getRehabTrees = createAction<Pagination>(
	addNamespace('rehabTrees/getRehabTrees'),
);
export const clearRehabTrees = createAction<Pagination>(
	addNamespace('rehabTrees/clearRehabTrees'),
);
export const clearRehabTreesDefault = () =>
	clearRehabTrees(rehabTreesPaginationDefault());
export const reloadRehabTrees = createAction(
	addNamespace('rehabTrees/reloadRehabTrees'),
);
export const getRehabTreesResolved = createAction<ResolvedResponse>(
	addNamespace('rehabTrees/getRehabTrees/resolved'),
);
export const getRehabTreesRejected = createAction<Status>(
	addNamespace('rehabTrees/getRehabTrees/rejected'),
);
export const changeRehabTreesSort = createAction<Sort2>(
	addNamespace('rehabTrees/sort'),
);
export const changeRehabTreesSlice = createAction<number>(
	addNamespace('rehabTrees/slice'),
);
export const changeRehabTreesPage = createAction<number>(
	addNamespace('rehabTrees/page'),
);
export const setRehabTreesIsBusy = createAction<boolean>('rehab/isBusy');
export const setRehabTreesLoaded = createAction('rehab/isLoaded');
export const renameRehabTree = createAction<{ treeId: string; name: string }>(
	'rehab/renameRehabTree',
);
export const setRehabTreesIsRenaming = createAction<boolean>(
	'rehab/setRehabTreesIsRenaming',
);
export const getRehabTreesList = createAction(
	addNamespace('rehabTrees/getRehabTreesList'),
);

export const getRehabTreesListResolved = createAction<RehabTreeList[]>(
	addNamespace('rehabTrees/getRehabTreesList/resolved'),
);

export const getRehabTreesListRejected = createAction<Status>(
	addNamespace('rehabTrees/getRehabTreesList/rejected'),
);

export const setRehabTreeNameError = createAction<string>(
	'rehabTress/setRehabTreeNameError',
);

export const setRefreshList = createAction<boolean>('rehab/setRefreshList');

export const setNewTreeName = createAction<NewDecisionTree>(
	'rehab/setNewTreeName',
);
export const addNodeToNewTree = createAction<ReactFlowSchema>(
	'rehab/addNodeToNewTree',
);
export const setNodeCondition = createAction<NodeConditionParams>(
	'rehab/setNodeCondition',
);
export const setNodePosition = createAction<NodePositionParams>(
	'rehab/setNodePosition',
);
export const addRehabTree = createAction<{ history: any }>(
	'rehab/addRehabTree',
);
export const saveRehabTree = createAction<string>('rehab/saveRehabTree');
export const deleteRehabTree = createAction<{
	treeId: string;
	refresh: () => void;
}>('rehab/deleteRehabTree');

export const deleteRehabTreeResolved = createAction<{
	treeId: string;
}>('rehab/deleteRehabTreeResolved');
export const deleteRehabTreeRejected = createAction(
	'rehab/deleteRehabTreeRejected',
);
export const addRehabTreeRejected = createAction('rehab/addRehabTreeRejected');
export const addRehabTreeResolved = createAction('rehab/addRehabTree/resolved');

export const getRehabActions = createAction<GetRehabActionsParams>(
	'rehab/getRehabActions',
);
export const getRehabActionsRejected = createAction(
	'rehab/getRehabActionsRejected',
);
export const getRehabActionsResolved = createAction<RehabAction[]>(
	'rehab/getRehabActions/resolved',
);

// export const getRehabAction = createAction<GetRehabActionParams>(
// 	'rehab/getRehabTree',
// );
// export const getRehabActionRejected = createAction('rehab/getRehabActionRejected');
// export const getRehabActionResolved = createAction<RehabAction>(
// 	'rehab/getRehabAction/resolved',
// );

export const addRehabAction = createAction<{
	rehabAction: RehabAction;
}>('rehab/addRehabAction');
export const addRehabActionRejected = createAction(
	'rehab/addRehabActionRejected',
);
export const addRehabActionResolved = createAction<RehabAction>(
	'rehab/addRehabAction/resolved',
);
export const addNewRehabActionToTree = createAction<RehabAction | undefined>(
	'rehab/addRehabAction/addToTree',
);

export const deleteRehabAction = createAction<string>(
	'rehab/deleteRehabAction',
);

export const deleteRehabActionResolved = createAction<string>(
	'rehab/deleteRehabActionResolved',
);
export const deleteRehabActionRejected = createAction(
	'rehab/deleteRehabActionRejected',
);

export const saveRehabAction = createAction<{
	rehabAction: RehabAction;
}>('rehab/saveRehabAction');
export const saveRehabActionRejected = createAction(
	'rehab/saveRehabActionRejected',
);
export const saveRehabActionResolved = createAction<RehabAction>(
	'rehab/saveRehabAction/resolved',
);

export const validateRehabTree = createAction<ValidateRehabTreeParams>(
	'rehab/validateRehabTree',
);
export const validateRehabTreeRejected = createAction(
	'rehab/validateRehabTreeRejected',
);
export const validateRehabTreeResolved = createAction(
	'rehab/validateRehabTreeResolved',
);

export const runRehabTree =
	createAction<RunRehabTreeParams>('rehab/runRehabTree');
export const runRehabTreeRejected = createAction('rehab/runRehabTreeRejected');
export const runRehabTreeResolved = createAction('rehab/runRehabTreeResolved');

export const getRehabResult = createAction('rehab/getRehabResult');
export const reloadRehabResult = createAction('rehab/reloadRehabResult');
export const getRehabResultResolved = createAction<ResolvedResponse>(
	'rehab/getRehabResult/resolved',
);
export const getRehabResultRejected = createAction<Status>(
	'rehab/getRehabResult/rejected',
);
export const changePage = createAction<number>('rehabresult/page');
export const changePagination = createAction<{ page?: number; slice?: number }>(
	'rehabresult/paginationModel',
);
export const clearScanId = createAction(
	addNamespace('rehabresult/clearScanId'),
);
export const changeSearch = createAction<string>('rehabresult/changeSearch');
export const changeSort = createAction<Sort>('rehabresult/sort');
export const setSelected = createAction<string[]>('rehabresult/selected/set');
export const addSelected = createAction<string[]>('rehabresult/selected/add');
export const removeSelected = createAction<string[]>(
	'rehabresult/selected/remove',
);
export const clearSelected = createAction('rehabresult/selected/clear');
export const currentRow = createAction<number>('rehabresult/current/row');
export const setRehabResultIsBusy = createAction<boolean>('rehabresult/isBusy');
export const changeFilterSelect = createAction<ColumnFilterValue>(
	addNamespace('rehabresult/filterSelect'),
);
export const changeFiltersSelect = createAction<ColumnFilterValue[]>(
	addNamespace('rehabresult/filterSelect'),
);

export const updateColumnFilterSelectOptions =
	createAction<ColumnFilterOptions>(
		addNamespace('rehabresult/filterSelect/column/options'),
	);

export const translate = createAction<Translations>(
	addNamespace('rehabresult/translate'),
);

export const addRehabTreeValidationResult = createAction<RehabTreeValidation>(
	'rehab/addRehabTreeValidationResult',
);

export const changeTreeUpdateStatus = createAction(
	'rehab/changeTreeUpdateStatus',
);
export const lockAction = createAction<RehabResults>('rehabresult/lockAction');

export const lockActionResolved = createAction(
	'rehabresult/lockAction/resolved',
);

export const lockActionRejected = createAction(
	'rehabresult/lockAction/rejected',
);

export const editAction = createAction<RehabResults>('rehabresult/editAction');

export const editActionResolved = createAction(
	'rehabresult/editAction/resolved',
);

export const editActionRejected = createAction(
	'rehabresult/editAction/rejected',
);

export const updateRehabTreeStatus = createAction<string>(
	'rehab/updateRehabTreeStatus',
);

export const resetFilters = createAction('rehabresult/resetFilters');

export const duplicateRehabTree = createAction<RehabTreeId>(
	'rehab/duplicateRehabTree',
);

export const duplicateRehabTreeRejected = createAction(
	'rehab/duplicateRehabTreeRejected',
);
export const duplicateRehabTreeResolved = createAction(
	'rehab/duplicateRehabTreeResolved',
);

export const setStartNodeId = createAction<string>('rehab/setStartNodeId');

export const getRehabCost = createAction<RehabCostParams>('rehab/getRehabCost');
export const getRehabCostResolved = createAction<RehabCost>(
	'rehab/getRehabCost/resolved',
);
export const getRehabCostRejected = createAction<Status>(
	'rehab/getRehabCost/rejected',
);

export const setAllRiskConfigModels = createAction<RiskConfig[]>(
	'rehab/setAllRiskConfigModels',
);

export const clearCurrentTree = createAction('rehab/clearCurrentTree');

export const changeRehabResultsFilter = createAction<
	GridFilterModel | undefined
>(addNamespace('rehabresults/filter'));
